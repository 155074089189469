/* eslint-disable no-unused-vars */
export enum Environment {
    Development = 'Development',
    Staging = 'Staging',
    Production = 'Production'
}
/* eslint-enable no-unused-vars */

export class Logs {
    public static readonly MaxSizeInMbToDownload: number = 1;
}

export class ParameterType {
    public static readonly DateTime: string = 'DateTime';
    public static readonly Date: string = 'Date';
    public static readonly Boolean: string = 'Boolean';
    public static readonly MultiLine: string = 'Multiline';
    public static readonly SingleLine: string = 'String';
    public static readonly Integer: string = 'Integer';
    public static readonly Enum: string = 'Enum';
}

export class Status {
    public static readonly success: string = 'Success';
    public static readonly warning: string = 'Warning';
    public static readonly fail: string = 'Fail';
}

export class SearchFilters {
    public static readonly functionary: string = 'functionary';
    public static readonly fullName: string = 'fullName';
    public static readonly firstName: string = 'firstName';
    public static readonly lastName: string = 'lastName';
    public static readonly code: string = 'code';
    public static readonly id: string = 'id';
    public static readonly exactMatch: string = 'exactMatch';
    public static readonly inactiveInclude: string = 'inactiveInclude';
    public static readonly deletedInclude: string = 'deletedInclude';
}

export class ImportSalaryType {
    public static readonly tickets: number = 101;
    public static readonly ticketsRefund: number = 102;
    public static readonly dutyFree: number = 103;
    public static readonly shiftBonus: number = 104;
}

export class Activity {
    public static readonly SUPPORT: string = 'SUPPORT';
    public static readonly PIKETT: string = 'PIKETT';
}
