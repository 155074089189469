import React, {Component, ReactNode} from 'react';
import ReactResizeDetector from 'react-resize-detector';

import {Cell, Row, ScrollerSettings, VirtualScroll} from '@Components';
import {Loading} from '@Components/Loading/Loading';
import {CellAttributes, CellData, OldCellProps, ColumnSize, DataGridProps, ICellRender, TableRowRenderArgs, CustomizedCellRenderProps} from '../gridtypes';
import {cellRenderer, defaultHeaderCellRender} from '../Cell/CellRenderer';

import './grid.scss';

function defaultComparer<T>(a: T, b: T, key: keyof T): number {
    if (a[key] < b[key]) {
        return -1;
    }
    if (a[key] > b[key]) {
        return 1;
    }
    return 0;
}

type DataGridState = {
    columns: ColumnSize[];
};

//TODO: recheck @observer
export class Grid<T> extends Component<DataGridProps<T>, DataGridState> {
    private _tableBodyRef: React.RefObject<HTMLDivElement> = React.createRef();
    private _tableHeaderRef: React.RefObject<HTMLDivElement> = React.createRef();
    private _scrollBarWidth: number = 0;

    constructor(props: DataGridProps<T>) {
        super(props);
        this.state = {
            columns: []
        };
    }

    componentDidMount() {
        this._scrollBarWidth = this._getScrollBarWidth();
    }

    componentDidUpdate() {
        if (!this.state.columns.length) {
            const columns = React.Children.toArray(this.props.children);

            if (columns.length) {
                const initialSizes = columns.map((col, i) => {
                    return {name: 'col' + i, width: undefined};
                });
                this.setState({columns: initialSizes});
            }
        }
    }

    render() {
        const {className, virtualized, onRowHover, totalRow, sizeConfig} = this.props;
        const cls = ['data-grid'];

        if (className) cls.push(className);

        let style: React.CSSProperties = {};

        if (sizeConfig) {
            const {width, height} = sizeConfig;
            if (width) {
                style = Object.assign({width}, style);
            }

            if (height) {
                style = Object.assign({height}, style);
            }
        }

        return (
            <div className={cls.join(' ')} onMouseLeave={() => onRowHover?.()} style={style}>
                {this._renderHeader()}
                {virtualized ? this._renderVirtualizedBody() : this._renderBody()}
                {totalRow && this._renderTotal()}
            </div>
        );
    }

    get virtualRowHeight() {
        return this.props.virtualRowHeight ?? 25;
    }

    private _renderHeader() {
        const { headerHeight} = this.props;
        const columns = this._renderColumns({isHeaderRow: true});

        const clsThead = ['thead'];

        const rowPaddingRight = this._getRowRightOffset();

        return (
            <ReactResizeDetector handleHeight onResize={this.props.onResizeHeader} targetRef={this._tableHeaderRef}>
                <div ref={this._tableHeaderRef} className={clsThead.join(' ')} style={{ minHeight: headerHeight || 'auto' }}>
                    <Row style={{ paddingRight: rowPaddingRight }}>
                        {columns}
                    </Row>
                </div>
            </ReactResizeDetector>
        );
    }

    private _renderTotal() {
        const { dataSource } = this.props;
        const columns = this._renderColumns({isTotalRow: true, dataSource});

        const cls = [];

        cls.push('total-row');

        const rowPaddingRight = this._getRowRightOffset();

        return (
            <div className={cls.join(' ')}>
                <Row style={{ paddingRight: rowPaddingRight }}>
                    {columns}
                </Row>
            </div>
        );
    }

    private _renderBody() {
        const { dataSource, loading, virtualized, groupingKey, onGroupingRowRender, onRowRender, onOrderGroup } = this.props;

        const cls = ['tbody'];

        cls.push('customized-scroll');
        if (virtualized) {
            cls.push('scroll-indent');
        }

        let rows: JSX.Element[] = [];
        if (groupingKey) {
            const items = dataSource.slice(0);
            items.sort((a, b) => {
                let result = defaultComparer<T>(a, b, groupingKey);
                if (onOrderGroup && result === 0){
                    return onOrderGroup(a, b);
                }
                return result;
            });
            let currentGroupItem: unknown | undefined;
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                const groupValue = item[groupingKey];
                if (groupValue !== currentGroupItem) {
                    currentGroupItem = groupValue;

                    const attrs: React.HTMLAttributes<HTMLDivElement> & { [key: string]: unknown } = {
                        className: 'grouping-row',
                        key: `${String(groupingKey)}_${currentGroupItem}`
                    };

                    let args: TableRowRenderArgs<T> = {
                        child: null,
                        rowAttributes: attrs,
                        item: item
                    };

                    onGroupingRowRender?.(args);

                    rows.push(React.createElement('div', attrs, args.child ?? <>{currentGroupItem as unknown as ReactNode}</>));
                }
                rows.push(
                    <Row key={'g' + i}>
                        {this._renderColumns({ item })}
                    </Row>
                );
            }
        } else {
            rows.push(...dataSource.map((item, i) => {
                return (
                    <Row
                        key={i}
                        onRender={(args) => {
                            const rowArgs: TableRowRenderArgs<T> = {
                                item: item,
                                rowAttributes: args.rowAttributes,
                                child: args.child
                            };
                            onRowRender?.(rowArgs);
                        }}>
                        {this._renderColumns({ item })}
                    </Row>
                );
            }));
        }

        return (
            <div className={cls.join(' ')}>
                {rows}
                {!dataSource.length && <div className="no-data-paceholder">No data loaded</div>}
                <Loading loading={loading}/>
            </div>
        );
    }

    private _renderVirtualizedBody() {
        const {onScroll, scrollTop, dataSource, loading} = this.props;
        if (!onScroll) {
            return;
        }

        return (
            <ReactResizeDetector handleHeight targetRef={this._tableBodyRef}>
                {({height}) => {

                    const virtualSettings: ScrollerSettings = {
                        itemHeight: this.virtualRowHeight,
                        scrollTop: (dataSource.length && scrollTop) || 0,
                        virtualHeight: dataSource.length * this.virtualRowHeight,
                        viewportHeight: height || 0
                    };

                    return (
                        <div className="tbody" ref={this._tableBodyRef}>
                            <VirtualScroll
                                dataSource={dataSource}
                                settings={virtualSettings}
                                row={this.rowTemplate}
                                onScroll={onScroll}
                            />
                            <Loading loading={loading}/>
                        </div>
                    );

                }}
            </ReactResizeDetector>
        );
    }

    private rowTemplate = (item: T) => {
        const {dataSource, selectedRow, onRowClick, onRowDoubleClick, onRowHover, onRowRender, highlightedRow} = this.props;
        const columns = this._renderColumns({item});
        const index = dataSource.indexOf(item);
        const highlighted = item === highlightedRow;
        const selected = item === selectedRow;
        const rowStyle = this._getRowStyle(highlighted, selected);

        return (
            <Row
                key={index}
                style={rowStyle}
                classNamePreffix="item"
                selected={selected}
                highlighted={highlighted}
                onClick={() => onRowClick?.(item)}
                onRowDoubleClick={() => onRowDoubleClick?.(item)}
                onHover={() => onRowHover?.(item)}
                onRender={(args) => {
                    const rowArgs: TableRowRenderArgs<T> = {
                        item: item,
                        rowAttributes: args.rowAttributes,
                        child: args.child
                    };
                    onRowRender?.(rowArgs);
                    args.child = rowArgs.child;
                    args.rowAttributes = rowArgs.rowAttributes;
                }}
            >
                {columns}
            </Row>
        );
    };

    private _renderColumns(rowConfig: Partial<OldCellProps<T>>) {
        const {children} = this.props;
        const columns = React.Children.toArray(children);

        return React.Children.map(columns, (child, index) => {
            const columnProps = (child as JSX.Element).props;
            const cellProps = Object.assign({key: 'col' + index}, columnProps, rowConfig);
            return this._renderCell(cellProps, 'col' + index);
        });
    }

    _renderCell(cellProps: OldCellProps<T>, key: string) {
        const {allowColumnResize} = this.props;
        const {item, dataField, caption, onDoubleClick, dataSource, className} = cellProps;
        const {isHeaderRow, isTotalRow, onFooterCellPrepare} = cellProps;
        const value = item && dataField ? item[dataField] as unknown : void 0 as unknown;

        // TODO: check styles
        const attrs: React.HTMLAttributes<HTMLDivElement> & { [key: string]: unknown } = {
            className: '',
            style: {}
        };

        if (className) {
            attrs.className = className;
        }

        if (onDoubleClick) {
            attrs.onDoubleClick = (e: React.MouseEvent<HTMLDivElement>) => {
                onDoubleClick({caption, dataField, item, value, isHeaderRow, isTotalRow});
            };
        }

        if (isTotalRow && onFooterCellPrepare) {
            attrs.style = onFooterCellPrepare?.();
        }

        const cellAttrs: CellAttributes = {
            htmlAttributes: attrs,
            minWidth: cellProps.minWidth,
            maxWidth: cellProps.maxWidth,
            className: attrs.className,
            onDoubleClick: attrs.onDoubleClick
        };

        const cellData: CellData<T> = {
            dataField,
            dataSource,
            value,
            caption,
            row: item
        };

        const cr = this._getCellRender(cellProps);
        const col = this.state.columns.find(c => c.name === key);

        return (
            <Cell
                resizable={allowColumnResize && isHeaderRow}
                columns={this.state.columns}
                column={col}
                cellAttributes={cellAttrs}
                cellData={cellData}
                cellRender={cr}
                onUpdateColumnWidth={this._handleColumnWidthChange(col)}
            />
        );
    }

    private _handleColumnWidthChange(column?: ColumnSize) {
        return (width: number) => {
            if (column) {
                column.width = width;
                this.forceUpdate();
            } else {
                this.setState({columns: [...this.state.columns, {name: 'col', width}]});
            }
        };
    }

    private _getCellRender(cellProps: CustomizedCellRenderProps<T>): ICellRender<T, unknown> {
        const {isHeaderRow, isTotalRow, headerCellRender, footerCellRender, cellRender} = cellProps;

        if (isHeaderRow) {
            return !!headerCellRender ? headerCellRender : defaultHeaderCellRender;
        }

        if (isTotalRow && footerCellRender) {
            return footerCellRender;
        }

        if (!isHeaderRow && !isTotalRow && cellRender) {
            return cellRender;
        }

        return cellRenderer;
    };

    private _getRowStyle(highlighted: boolean, selected?: boolean): React.CSSProperties {
        const style: React.CSSProperties = {};
        if (this.props.virtualized) {
            style.height = this.props.virtualRowHeight;
        }
        if (selected) style.backgroundColor = '#ffc107F0';
        if (highlighted) style.backgroundColor = '#ffc10750';
        return style;
    }

    private _getScrollBarWidth() {
        const el = document.createElement('div');

        el.style.cssText = 'overflow:scroll; visibility:hidden; position:absolute;';
        el.classList.add('customized-scroll');

        document.body.appendChild(el);

        const width = el.offsetWidth - el.clientWidth;

        el.remove();

        return width;
    }

    private _getRowRightOffset() {
        if (!this.props.useScrollbarIndent) return;

        const scrollBarWidth = this._scrollBarWidth;
        const borderWidth = 1;

        return scrollBarWidth + borderWidth;
    }
}