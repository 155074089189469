import { PureComponent} from 'react';
import { observer } from 'mobx-react';
// import { Cell } from '@Components';
import { ColumnProps } from '../gridtypes';

@observer
export class Column<TItem, TValue = unknown> extends PureComponent<ColumnProps<TItem, TValue>> {
    render() {
        // TODO: 
        // return <Cell {...this.props}/>;
        return null;
    }
}
