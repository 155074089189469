import { ApiService, ResponseError, ResponseInterceptor } from './ApiService';

export type TimelineItem = {
    Text?: string;
    CssColor?: string;
    OperationName?: string;
    OperationParameters?: string;
    StartTimeMs: number;
    EndTimeMs: number;
    IsException?: boolean;
    ExceptionMessage?: string;
};

export type RequestTimelineData = {
    StartUtc: string;
    TotalTimeMs: number;
    TimelineName: string;
    TimelineParameters: string;
    Items: TimelineItem[];
    Raw?: string;
};

class RequestTimelineService {
    private static _instance: RequestTimelineService;

    private _interceptor: ResponseInterceptor = {
        response: (res) => {
            this.addTimeline(res.headers['x-request-timeline']);
            return res;
        },
        error: (err) => {
            this._addErrorTimeline(err);
            throw err;
        }
    };

    constructor() {
        if (RequestTimelineService._instance) {
            throw new Error('You can\'t initialize several instances. Use getInstance instead');
        }
    }

    public static getInstanse(): RequestTimelineService {
        if (!RequestTimelineService._instance) {
            RequestTimelineService._instance = new RequestTimelineService();
        }

        return RequestTimelineService._instance;
    }

    public init() {
        ApiService.addResponseInterceptor(this._interceptor);
    }

    public addTimeline(rawData: string) {
        const requestLog = localStorage.getItem('DevToolRequestLog');
        if (requestLog && rawData) {
            const maxLogSize = 1 * 1024 * 1024;
            const newArray: string[] = [];
            const existingArray = JSON.parse(requestLog) as string[];
            let minIndexToKeep = existingArray.length - 1;
            let size = rawData.length;
            for (; minIndexToKeep >= 0; minIndexToKeep--) {
                if (!existingArray[minIndexToKeep])
                    continue;

                if (size + existingArray[minIndexToKeep].length > maxLogSize)
                    break;

                size += existingArray[minIndexToKeep].length;
            }

            for (let i = minIndexToKeep; i < existingArray.length; i++) {
                if (!existingArray[i])
                    continue;

                newArray.push(existingArray[i]);
            }

            if (newArray.indexOf(rawData) === -1) {
                newArray.push(rawData);
            }
            localStorage.setItem('DevToolRequestLog', JSON.stringify(newArray));
        }
    }

    public readTimeline(lastRawLength: number) {
        const savedRequestsRaw = localStorage.getItem('DevToolRequestLog') || '';
        if (savedRequestsRaw.length === lastRawLength)
            return null;

        const rawRequests = JSON.parse(savedRequestsRaw) as string[];
        return rawRequests;
    }

    public clear() {
        localStorage.setItem('DevToolRequestLog', JSON.stringify([]));
    }

    public setValue(value: string) {
        localStorage.setItem('DevToolRequestLog', value);
    }

    private _addErrorTimeline(err: ResponseError) {
        if (err.response && err.response.status !== 401 && err.response.status !== 403) {
            this.addTimeline(err.response.headers['x-request-timeline']);
        }
    }
}

export const requestTimelineService = RequestTimelineService.getInstanse();
