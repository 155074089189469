import * as React from 'react';
import DateTimeField from '@1stquad/react-bootstrap-datetimepicker';

import {DateTime} from '@AppConstants';
import {DateTimeService} from '@Services';

import './date-picker.scss';

export type DatePickerProps = {
    wrapperClassName?: string;
    disabled?: boolean;
    invalid?: boolean;
    showToday?: boolean;

    minDate?: Date;
    maxDate?: Date;
    value: string;
    availableDates?: Date[];

    viewMode?: string;
    pickerMode?: string;
    pickerSize?: string;

    daysOfWeekDisabled?: number[];
    allDaysDisabled?: boolean;

    onBlur?: Function;
    onEnter?: Function;
    onChange?: (date: Date, value: string) => void;
};

export class DatePicker extends React.Component<DatePickerProps, {}> {
    render() {
        const {disabled, minDate, maxDate, onBlur, onEnter, pickerMode, pickerSize, daysOfWeekDisabled, viewMode, availableDates, invalid } = this.props;
        const {value, wrapperClassName, onChange, showToday} = this.props;
        const cls: string[] = [];
        const minimalDate = minDate && DateTimeService.isValidDate(minDate) ? minDate : DateTimeService.parse(DateTime.minDate, DateTime.viewDateFormat);
        const maximalDate = maxDate && DateTimeService.isValidDate(maxDate) ? maxDate : undefined;

        const dateTime = DateTimeService.parse(value, this._getPickerFormat(pickerMode));
        const finalValue = DateTimeService.isValidDate(dateTime) ? dateTime : value;

        wrapperClassName && cls.push(wrapperClassName);
        // const validatedClass = formModel.formValidatedClass;
        const validatedClass = 'skin';
        validatedClass && cls.push(validatedClass);

        const validationClass = invalid ? 'is-invalid' : '';
        if (showToday === false) {
            cls.push('show-today-disabled');
        }
        return (
            <div className={cls.join(' ')}>
                <DateTimeField
                    dateTime={finalValue}
                    hideInputIcon
                    inputFormat={this._getPickerFormat(pickerMode)}
                    onChange={onChange}
                    onBlur={onBlur ? onBlur : undefined}
                    onEnterKeyDown={onEnter ? onEnter : undefined}
                    mode={pickerMode ? pickerMode : 'date'}
                    viewMode={viewMode ? viewMode : 'date'}
                    size={pickerSize ? pickerSize : 'sm'}
                    minDate={minimalDate}
                    maxDate={maximalDate}
                    disabled={disabled}
                    daysOfWeekDisabled={daysOfWeekDisabled ? daysOfWeekDisabled : []}
                    validationClass={validationClass}
                    availableDates={availableDates}
                />
            </div>
        );
    }

    private _getPickerFormat(mode?: string): string {
        if (mode === 'datetime') {
            return DateTime.viewFullFormat;
        }
        if (mode === 'time') {
            return DateTime.timeFormat;
        }
        return DateTime.viewDateFormat;
    }
}