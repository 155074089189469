/* eslint-disable @typescript-eslint/no-explicit-any */
import { action, computed, makeObservable, observable } from 'mobx';
import { Location } from 'history';

class NavigationStore {
    private _router: any;
    @observable private _currentLocation: Location;

    constructor() {
        makeObservable(this);
    }

    @action
    setRouter(router: any) {
        if (router) {
            this._router = router;
            this._currentLocation = this._router.history.location;
            this._router.history.listen((location: Location) => {
                this._currentLocation = location;
            });
        }
    }

    //NAVIGATION
    @action
    redirect(url: string, state?: any) {
        if (state) {
            this._router.history.push({ pathname: url, state: state });
        } else {
            this._router.history.push(url);
        }
    }

    @computed
    get currentLocation() {
        return this._currentLocation;
    }
}

export const navigationStore = new NavigationStore();