import React from 'react';
// import styled, { css } from 'styled-components';
type DragEvent = {
	translateX: number;
	translateY: number;
};

type DNDProps = {
	onDragStart?: () => void;
	onDragEnd?: () => void;
	onDrag?: (e: DragEvent) => void;
};

type DNDState = {
	translateX: number;
	translateY: number;

	originalX: number;
	originalY: number;

	lastTranslateX: number;
	lastTranslateY: number;
	isDragging: boolean;
};

export default class Draggable extends React.PureComponent<DNDProps, DNDState> {
	state = {
		isDragging: false,

		originalX: 0,
		originalY: 0,

		deltaX: 0,

		translateX: 0,
		translateY: 0,

		lastTranslateX: 0,
		lastTranslateY: 0
	};

	componentWillUnmount() {
		window.removeEventListener('mousemove', this.handleMouseMove);
		window.removeEventListener('mouseup', this.handleMouseUp);
	}

	handleMouseDown = ({ clientX, clientY }: React.MouseEvent) => {
		window.addEventListener('mousemove', this.handleMouseMove);
		window.addEventListener('mouseup', this.handleMouseUp);

		if (this.props.onDragStart) {
			this.props.onDragStart();
		}

		this.setState({
			originalX: clientX,
			originalY: clientY,
			isDragging: true
		});
	};

	handleMouseMove = ({ clientX, clientY }: MouseEvent) => {
		const { isDragging } = this.state;
		const { onDrag } = this.props;

		if (!isDragging) {
			return;
		}

		this.setState(prevState => ({
			translateX: clientX - prevState.originalX,
			translateY: clientY - prevState.originalY
			// translateX: clientX - prevState.originalX + prevState.lastTranslateX,
			// translateY: clientY - prevState.originalY + prevState.lastTranslateY
		}), () => {
			if (onDrag) {
				onDrag({
					translateX: this.state.translateX,
					translateY: this.state.translateY
				});
			}
		});
	};

	handleMouseUp = () => {
		window.removeEventListener('mousemove', this.handleMouseMove);
		window.removeEventListener('mouseup', this.handleMouseUp);

		this.setState(
			{
				originalX: 0,
				originalY: 0,
				lastTranslateX: this.state.translateX,
				lastTranslateY: this.state.translateY,
				isDragging: false
			},
			() => {
				if (this.props.onDragEnd) {
				this.props.onDragEnd();
				}
			}
		);
	};

	render() {
		const { children } = this.props;
		const { translateX, translateY, isDragging } = this.state;

		return (
			<Container
				onMouseDown={this.handleMouseDown}
				x={translateX}
				y={translateY}
				isDragging={isDragging}
			>
				{children}
			</Container>
		);
	}
}

type ContainerProps = {
	onMouseDown: (e: React.MouseEvent) => void;
	x: number;
	y: number;
	isDragging: boolean;
};

class Container extends React.PureComponent<ContainerProps> {
	render() {
		const { onMouseDown } = this.props;
		// const { x, y, isDragging, onMouseDown } = this.props;
		return (
			<div
				onMouseDown={onMouseDown}
				style={{ 
					// transform: `translate(${x}px, ${y}px)`,
					display: 'inline-block'
					// height: 20
				}}
			>
				{this.props.children}
			</div>
		);
	}
}
