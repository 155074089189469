import * as React from 'react';
import { AiFillQuestionCircle } from 'react-icons/ai';
import './help-link.scss';

type HelpLinkProps = {
	link?: string;
	className?: string;
};

export class HelpLink extends React.PureComponent<HelpLinkProps> {
	public render() {
		const { link, className } = this.props;
		const cls = ['help-link'];
		if (className) cls.push(className);

		return (
			<div className={cls.join(' ')}>
				<a title="Help document" target="_blank" href={link} rel="noreferrer"> </a>
				<AiFillQuestionCircle className="icon" size={16}/>
			</div>
		);
	}
}