import { ApiClient, FileParameter } from '@Models';
import { IAjaxOptions } from '../../Services';
export class ImportDialogStore {
    private _apiClient = new ApiClient();
    private _typeId: number;

    constructor(typeId: number) {
        this._typeId = typeId;
    }

    public async uploadFile(periodId: number, file: File) {
        const param: FileParameter = {
            data: file,
            fileName: file.name
        };

        const options: IAjaxOptions = {
            suppressErrorHandling: true,
            customApiHeader: { 'content-type': 'multipart/form-data' }
        };

        await this._apiClient.salaryUploadPost(periodId, this._typeId, param,  options);
    }
}