import { ApiUrls } from '@AppConstants';
import { PermissionsResult, PermissionType } from '@Models';
import { ApiService } from '@Services';
import { action, makeObservable, observable, runInAction } from 'mobx';

class SecurityService {
    @observable private personPermissions: PermissionType[] = [];
    @observable private originalPersonPermissions: PermissionType[] = [];

    constructor() {
        makeObservable(this);
    }

    @action
    async loadPermissions() {
        const permissionsResponse = await ApiService.getTypedData<PermissionsResult>(ApiUrls.PermissionUrl);

        runInAction(() => {
            this.personPermissions = permissionsResponse?.data?.permissions ?? [];
            this.originalPersonPermissions = permissionsResponse?.data?.originalPermissions ?? [];
        });
    }

    hasPermission = (permission: string) => {
        return this._hasPermissionInternal(permission);
    };

    hasOriginalPermission = (permission: string) => {
        return this.originalPersonPermissions.findIndex(x => x === permission) !== -1;
    };

    private _hasPermissionInternal(permission: string) {
        return this.personPermissions.findIndex(x => x === permission) !== -1;
    }

    hasAnyPermission = (permissions: string[]): boolean => {
        let result = false;
        permissions.forEach(permission => {
            if (this.personPermissions.findIndex(x => x === permission) !== -1)
                result = true;
        });
        return result;
    };
}

export const securityService = new SecurityService();