import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ApiClient, IPeriodsParams, ISalaryPeriodResponseItem, SalaryPeriodResponseItem, SalaryPeriodStatus } from '@Models';

type OptionChangeHandler = (period: ISalaryPeriodResponseItem | null) => void;
type DefaultOptionGetHandler = (options: ISalaryPeriodResponseItemExtended[]) => ISalaryPeriodResponseItemExtended | undefined;

export interface ISalaryPeriodResponseItemExtended extends ISalaryPeriodResponseItem {
	totalProgress?: number;
	position?: number;
};

export class MonthPickerStore {
	private _apiClient = new ApiClient();
	private _changeHandler?: OptionChangeHandler;
	private _getDefaultOption?: DefaultOptionGetHandler;
	private _periodStatus?: SalaryPeriodStatus;
	public selectedOption: ISalaryPeriodResponseItemExtended | null = null;
	public options: ISalaryPeriodResponseItemExtended[] = [];

	constructor(changeHandler?: OptionChangeHandler, periodStatus?: SalaryPeriodStatus, defaultPeriod?: SalaryPeriodResponseItem, getDefaultPeriod?: DefaultOptionGetHandler) {
			makeObservable(this, {
			  options: observable,
			  selectedOption: observable,
			  setSelectedOption: action.bound,
			  defaultOption: computed,
			  canClickNext: computed,
			  canClickPrevious: computed,
			  currentOption: computed,
			  currentOptionIndex: computed
		  });

		  this._changeHandler = changeHandler;
		  this._getDefaultOption = getDefaultPeriod;
		  this._periodStatus = periodStatus;
		  defaultPeriod && this.setSelectedOption(defaultPeriod);
		}

    public async loadData() {
		const params: IPeriodsParams =
		{
			status: this._periodStatus
		};

		const { data } = await this._apiClient.salaryPeriodsGet(params);
		runInAction(() => {
			this.options = data as ISalaryPeriodResponseItemExtended[];
			if (!this.selectedOption) {
				if (this._getDefaultOption) {
					const defaultOption = this._getDefaultOption(this.options);
					defaultOption && this.setSelectedOption(defaultOption);
				} else if (this.defaultOption) this.setSelectedOption(this.defaultOption);
			}
		});
	}

	public setSelectedOption(option: ISalaryPeriodResponseItemExtended) {
		this.selectedOption = option;
		this._changeHandler?.(option);
	}

	public get defaultOption(): ISalaryPeriodResponseItemExtended | null {
		return this.options[0] || null;
	}

	public get canClickNext() {
		return this.currentOptionIndex > 0;
	}

	public get canClickPrevious() {
		return this.currentOptionIndex < this.options.length - 1;
	}

	public get currentOption() {
		return this.selectedOption || this.defaultOption;
	}

	public get currentOptionIndex() {
		return this.options.findIndex(op => op.id === this.currentOption!.id);
	}
}
