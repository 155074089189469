import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Progress } from 'reactstrap';

import { securityService, signalRService } from '@Services';
import { SignalREvents } from '@AppConstants';
import { PermissionType, SalaryPeriodStatus } from '@Models';
import { ModalButtonType, ModalDialogOptions, ModalWindow } from '@Components';
import { MonthPicker, PeriodProgressEvent } from '@Components/MonthPicker/MonthPicker';
import { ImportPeriodDialogStore } from './ImportPeriodDialogStore';

type ImportPeriodDialogProps = {};

@observer
export default class ImportPeriodDialog extends Component<ImportPeriodDialogProps, {}> {
    private _store: ImportPeriodDialogStore = new ImportPeriodDialogStore();

    componentDidMount() {
        signalRService.subscribe<PeriodProgressEvent>(SignalREvents.periodImportProgress, this._onImportProgress);
    }

    componentWillUnmount() {
        signalRService.unsubscribe<PeriodProgressEvent>(SignalREvents.periodImportProgress, this._onImportProgress);
    }

    private _onImportProgress = (_: string, data: PeriodProgressEvent) => {
        this._store.updateProgress(data);
    };

    render() {
        const { importPeriod, allowOverride, progress } = this._store;
        if (!progress) return this.dialogFormNode;
        return (
            <>
                <p hidden={!importPeriod}>
                    Importing <b>{importPeriod?.codeName || ''}</b> - <small>Override <b>{allowOverride ? 'enabled' : ' is disabled'}</b></small>
                </p>
                <Progress
                    striped={true}
                    animated={true}
                    color={progress?.text === 'done' ? 'success' : ''}
                    max={progress?.total ? progress.total : 1}
                    value={progress?.position ? progress.position : 1}
                    className="progress-center-wrapper"
                >
                    <span className="progress-center text-center position-absolute w-100">
                        {progress?.text}
                    </span>
                </Progress>
            </>
        );

    }

    private get dialogFormNode() {
        const { onPeriodChange, onOverrideChange } = this._store;
        return (
            <>
                <MonthPicker onChange={onPeriodChange} />
                <br />
                <input type='checkbox' onChange={onOverrideChange} /><span> Allow Override</span>
            </>
        );
    }


    public getModalOptions(window: ModalWindow<void>): ModalDialogOptions<void> {
        if (this._store.progress) {
            return {
                buttons: [{
                    color: 'primary',
                    type: ModalButtonType.Close,
                    onClick: () => {
                        window.close();
                    },
                    title: 'Close and continue in background'
                }]
            };
        }
        return {
            buttons: [{
                type: ModalButtonType.Cancel,
                onClick: () => {
                    window.close();
                }
            }, {
                color: 'success',
                type: ModalButtonType.Confirm,
                isDisabled: this._store.period?.status === SalaryPeriodStatus.Released || (this._store.period?.status === SalaryPeriodStatus.ReadyToRelease && !securityService.hasPermission(PermissionType.ReleaseImportPeriod)),
                onClick: async () => {
                    await this._store.importSelectedPeriod();
                }
            }]
        };
    }
}