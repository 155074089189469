import React, { Component } from 'react';
import './body-container.scss';

export class BodyContainer extends Component {

    render () {
        return (
            <div className="body-container">
                {this.props.children}
            </div>
        );
    }
}
