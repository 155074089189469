import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { AxiosError } from 'axios';
import { action, makeObservable, observable } from 'mobx';

import { PermissionType } from '@Models';
import { RULE_UPDATED } from '../../AppConstants';

import { securityService } from '@Services';
import { ImportRulesStore } from './ImportRulesStore';
import { ModalButtonType, ModalDialogOptions, ModalWindow } from '../../Components';
import ChooseFileButton from '@Components/Buttons/ChooseFileButton/ChooseFileButton';
import FileRow from '../Import/FileRow/FileRow';

import './import-rules-dialog.scss';
@observer
class ImportRulesDialog extends Component<{}, {}> {
    public files: File[] = [];
    public fileInputRef = React.createRef<HTMLInputElement>();
    private readonly _isSingleFileMode = true;
    private readonly _acceptedFormats: string[] = ['text/xml'];
    private readonly _maxFileSize: number = 1024 * 1024 * 50;

    public loading: boolean = false;
    public progress: number = 0;
    public errors: string[] = [];
    private _store: ImportRulesStore;

    constructor(props: {}) {
        super(props);
        this._store = new ImportRulesStore();

        makeObservable(this, {
            _onFileChanged: action.bound,
            _handleDelete: action.bound,
            _handleCancel: action.bound,
            files: observable,
            fileInputRef: observable,
            progress: observable,
            loading: observable,
            errors: observable
        });
    }

    componentDidUpdate(): void {
        if (!securityService.hasPermission(PermissionType.EditRule)) {
            //TODO
            //navigationStore.redirect('/403');
            window.location.replace('/403');
        }
    }

    render() {
        const hasPermissionToImport = securityService.hasPermission(PermissionType.EditRule);
        if (!hasPermissionToImport) {
            return null;
        }

        return (
            <>
                {this.files.map((file, index) => 
                    <FileRow
                        key={file.name}
                        uploading={this.loading}
                        progressValue={this.progress}
                        fileName={file.name}
                        errors={this.errors}
                        onCancel={() => this._handleCancel(index)}
                        onDelete={() => this._handleDelete(index)}
                    />
                )}
                <div className="import-section">
                    <div>
                        <input
                            ref={this.fileInputRef}
                            multiple={!this._isSingleFileMode}
                            type="file"
                            accept={this._acceptedFormats.join(',')}
                            onChange={this._onFileChanged}
                            style={{ display: 'none' }}
                        />
                        {
                            this.files.length === 0 &&
                            <ChooseFileButton onClick={this._handleAddClick} />
                        }
                    </div>
                </div>
            </>
        );
    }

    public getModalOptions(window: ModalWindow<void>): ModalDialogOptions<void> {
        return {
            buttons: [
                {
                    type: ModalButtonType.Cancel,
                    onClick: () => window.close()
                },
                {
                    type: ModalButtonType.Save,
                    title: 'Import',
                    onClick: async () => {
                        if (!this.files.length) return;
                        
                        if (this._isSingleFileMode) {
                            try {
                                this.loading = true;
                                this.progress = 70;
                                await this._store.uploadFile(this.files[0]);
                                this.files = [];
                                this.progress = 100;
                                //NotificationHandler.showSuccess('The file is imported succesfully');
                                document.dispatchEvent(new Event(RULE_UPDATED));
                                window.close();
                            } catch (e) {
                                let message = (e as AxiosError).response?.data?.message;
                                if (!message) {
                                    message = (e as Error).message;
                                }

                                this.errors = [message];
                            } finally {
                                this.loading = false;
                                this.progress = 0;
                            }
                        }
                    }
                }
            ],
            width: '500px',
            bodyClassName: 'create-rule-dialog'
        };

    }
    private _handleAddClick = () => {
        const refInput = this.fileInputRef.current;

        if (refInput) {
            refInput.value = '';
            refInput.click();
        }
    };

    public _handleDelete(index: number) {
        this.files.splice(index, 1);
    }

    public _handleCancel(index: number) {
        this.files.splice(index, 1);
    }

    public _onFileChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        const files = Array.from(event.target.files);

        if (files && files.length) {
            const byFormat = (file: File) => this._acceptedFormats.indexOf(file.type) > -1;
            const bySize = (file: File) => file.size <= this._maxFileSize;

            const acceptedFiles = files.filter(byFormat).filter(bySize);
            if (acceptedFiles.length) {
                this.files = this._isSingleFileMode ? [acceptedFiles[0]] : [...this.files, ...acceptedFiles];
            }
        }
    };
};

export default ImportRulesDialog;