import React, { PureComponent } from 'react';
import { CellOptions, CellProps, DefaulCellTemptProps } from '../gridtypes';
import Draggable from '../Draggable/DraggableV1';
import { defaultCellRender } from './CellRenderer';
import './cell.scss';

type CellState = {
    frozenWidth: number;
    isResizing: boolean;
};

export class Cell<TItem, TValue = unknown> extends PureComponent<CellProps<TItem, TValue>, CellState> {
	static defaultProps: DefaulCellTemptProps<unknown, unknown>;
	cellRef: React.RefObject<HTMLDivElement> = React.createRef();

	constructor(props: CellProps<TItem, TValue>) {
		super(props);
		this._handleDrag = this._handleDrag.bind(this);
		this._handleDragStart = this._handleDragStart.bind(this);
		this._handleDragEnd = this._handleDragEnd.bind(this);
	}

	render() {
		const { cellRender, cellData, cellAttributes, resizable } = this.props;
		const { onDoubleClick } = cellAttributes;

		const cellOptions: CellOptions = {
			className: this._getCSSClass()
		};
		const cellContent = cellRender(cellData, cellOptions);
		const styles = this._getStyles();

		return (
			<div
				ref={this.cellRef}
				style={styles}
				className={cellOptions.className}
				title={cellOptions.hint}
				onDoubleClick={onDoubleClick}
			>
				{cellContent}
				{this.props.children}

				{resizable &&
				<Draggable
					onDragStart={this._handleDragStart}
					onDragEnd={this._handleDragEnd}
					onDrag={this._handleDrag}
				>
					<span className="resizer"/>
				</Draggable>}
			</div>
		);
	}

	private _getCSSClass() {
		const { cellAttributes: { cssClass, className } } = this.props;
		const css = ['cell'];

		if (cssClass) {
			css.push(cssClass);
		}

		if (className) {
			css.push(className);
		}

		return css.join(' ');
	}

	private _getStyles() {
		// TODO: get rid of enumeration all properties
		const { cellAttributes: { minWidth, maxWidth, htmlAttributes }, column } = this.props;
		const styles: React.CSSProperties = {};
		if (minWidth) styles.minWidth = minWidth;
		if (maxWidth) styles.maxWidth = maxWidth;
		if (column?.width) {
			// styles.width = column.width;
			styles.flexBasis = column.width;
			styles.flexGrow = 0;
			styles.flexShrink = 0;
		}
		

		if (htmlAttributes?.style?.backgroundColor) styles.backgroundColor = htmlAttributes.style.backgroundColor;
		return styles;
	}

	private _handleDragStart() {
		const frozenWidth = this._getCurrentCellWidth();
		this.setState({ isResizing: true, frozenWidth });
	}

	private _handleDrag(e: { translateX: number }){
        if (!this.state.isResizing) return;
        const { onUpdateColumnWidth } = this.props;
		const width = this.state.frozenWidth + e.translateX;
		onUpdateColumnWidth?.(width);
    }

	private _handleDragEnd() {
        this.setState({ isResizing: false, frozenWidth: 0 });
    }

	private _getCurrentCellWidth(): number {
		const { column } = this.props;
		return column?.width || this._getCurrentElementWidth();
	}

	private _getCurrentElementWidth(): number {
		return this.cellRef.current?.getBoundingClientRect().width || 0;
	}
}

Cell.defaultProps = {
	cellRender: defaultCellRender
};