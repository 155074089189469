import * as React from 'react';
import './tab.scss';

type TabProps = {
    active?: boolean;
    onClick: (e: React.MouseEvent) => void;
};

export const Tab: React.FunctionComponent<TabProps> = ({ active, onClick, children }) => {
    return (
        <div className={`tab${active ? ' active' : ''}`} onClick={onClick}>
            {children}
        </div>
    );
};
