import * as React from 'react';
import { FaSyncAlt } from 'react-icons/fa';
import './loading.scss';

type LoadingProps = {
	loading?: boolean;
	isSuspense?: boolean;
	className?: string;
} & LoadingSize;

type LoadingSize = {
	extrasmall?: boolean;
	small?: boolean;
	medium?: boolean;
	large?: boolean;

	fullpage?: boolean;
	customSize?: number;
	fillContainer?: boolean;
};

export class Loading extends React.PureComponent<LoadingProps, {}> {
	render() {
		const { loading, isSuspense, customSize } = this.props;
		const vilible = loading || isSuspense;
		const className = this._getLoaderStyles();
		
		return (
			<div hidden={!vilible} className={className}>
				<FaSyncAlt className="loader-icon" fontSize={customSize} />
			</div>
		);
	}

	private _getLoaderStyles(): string {
		const { extrasmall, small, medium, large, fullpage, fillContainer = true, className, isSuspense } = this.props;
		const cls: string[] = ['loader'];

		if (className) {
			cls.unshift(className);
		}

		if (isSuspense) {
			cls.push('loader-suspense');
		}

		if (fillContainer) {
			cls.push('loader-fill-container');
		}

		if (extrasmall) {
			cls.push('loader-extra-small');
		} else if (small) {
			cls.push('loader-small');
		} else if (medium) {
			cls.push('loader-medium');
		} else if (large) {
			cls.push('loader-large');
		} else if (fullpage) {
			cls.push('loader-full-page');
		} else {
			cls.push('loader-default');
		}

		return cls.join(' ');
	}
}