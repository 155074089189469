/* eslint-disable @typescript-eslint/no-explicit-any */
import { observable, action, computed, makeObservable, IComputedFactory } from 'mobx';
import { getDisplayName, ValidatioRule } from './Validation';

export class BaseFormModel<T> {
	@observable validated: boolean = false;

	constructor(){
		const computedFields = (this as any)['__computedFields'];
		const config: {[key: string]: IComputedFactory} = {};

		if (Array.isArray(computedFields)) {
			for(const fieldName of computedFields){
				config[fieldName] = computed;
			}
		}
        makeObservable<any>(this, config);
    }

	@action.bound 
	errorFor(name: keyof T) {
		return this.validationErrorsName(name);
	}

	nameFor<T extends {}>(this: T, name: keyof T) {
		return getDisplayName(this, name);
	}

	validatorsFor(this: T, name: keyof T) {
		const validators = (this as unknown as any)['__validators'] as ValidatioRule[];
		return validators.filter(v => v.fieldName === name);
	}

	@action.bound 
	isValid(name: keyof T) {
		return !this.validationErrorsName(name) || !this.validationErrorsName(name).length;
	}

	@action.bound 
	setValue(this: any, name: keyof T, value: string) {
		this[name] = value;
	}

	@action.bound
	validationErrorsName(this: any, name: keyof T) {
		const fieldName = name as string;
		return this['__validateError_' + fieldName[0].toUpperCase() + fieldName.substr(1)];
	}

	@action.bound
	fieldValidateClass(this: any, name: keyof T) {
		return this.invalidFields.includes(name) ? 'is-invalid' : '';
	}

	@computed
	get isFormValid() {
        return (this as any).__validators ? !!(this as any).__isValidForm : true;
	}

	@computed
	get invalidFields(): string[] {
		return (this as any).__validateError_names || [];
	}

	@computed
	get formValidatedClass() {
		return this.validated ? 'validated' : 'not-validated';
	}

	@action.bound
	validate(this: any): boolean {
		this.validated = true;
		return this.isFormValid;
	}
}