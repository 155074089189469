import * as React from 'react';
import { observer } from 'mobx-react';
import { FormGroup } from 'reactstrap';
import { IModalDialogContent, ModalButtonType, ModalDialogOptions, ModalWindow, FormCheckbox } from '@Components';
import { FormModel, ImpersonationStore } from './ImpersonationDialogStore';

export type ImpersonationDialogProps = {
    canImpersonateAsAdmin: boolean;
};

@observer
export default class ImpersonationDialog extends React.Component<ImpersonationDialogProps, {}> implements IModalDialogContent<void> {
    private _store: ImpersonationStore;

    constructor(props: ImpersonationDialogProps) {
        super(props);
        this._store = new ImpersonationStore(props);
    }

    public getModalOptions(window: ModalWindow<void>): ModalDialogOptions<void> {
        const { onEnterImpersonation, form } = this._store;

        return {
            title: 'Impersonation',
            buttons: [{
                title: 'Close',
                type: ModalButtonType.Cancel,
                onClick: () => {
                    window.close();
                }
            }, {
                title: 'Impersonate',
                type: ModalButtonType.Save,
                isDisabled: !form.isFormValid,
                onClick: onEnterImpersonation(() => window.close())
            }],
            width: '500px'
        };
    }

    render() {
        const { azureRoles } = this._store;
        return (
            <FormGroup>
                {azureRoles.length >= 1 &&
                    <React.Fragment>
                        <div className="filters-list-item-title">Roles:</div>
                        {azureRoles.map(r =>
                            <div>
                                <FormCheckbox<FormModel>
                                    key={r.name}
                                    name={r.name as keyof FormModel}
                                    formModel={this._store.form}
                                    label={r.name}
                                    className="impersonate-role"
                                    checked={false}
                                />
                            </div>
                        )}
                    </React.Fragment>}
            </FormGroup>
        );
    }
}
