import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@1stquad/react-bootstrap-datetimepicker/css/bootstrap-datetimepicker.css';
import './Scss/App.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './../node_modules/font-awesome/css/font-awesome.css'; // TODO: update picker and remove. Legacy for datetime picker

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
