import { makeAutoObservable } from 'mobx';
import { ApiClient, IReleaseParams, ISalaryPeriodResponseItem } from '@Models';



export class ReleasePeriodDialogStore {
    private _apiClient = new ApiClient();
    public period: ISalaryPeriodResponseItem | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    public setPeriod = (period: ISalaryPeriodResponseItem | null) => {
        this.period = period;
    };

    public async releaseSelectedPeriod() {
        let params: IReleaseParams =
        {
            periodId: this.period?.id ?? 0
        };
        await this._apiClient.dutyPeriodByPeriodIdReleasePut(params);
    }

    public async rejectSelectedPeriod() {
        let params: IReleaseParams =
        {
            periodId: this.period?.id ?? 0
        };
        await this._apiClient.dutyPeriodByPeriodIdRejectPut(params);
    }
}