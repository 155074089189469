import * as React from 'react';
import './nav-group.scss';

export type NavGroupProps = {
    name?: JSX.Element | string;
    className?: string;
};

export class NavGroup extends React.Component<NavGroupProps, {}> {
    render() {
        const {name, className} = this.props;
        const cls = [];
        if (className) cls.push(className);
        cls.push('nav-group');
        if (this.props.children) cls.push('has-links');

        return (
            <div className={cls.join(' ')}>
                <span className="nav-group-name">
                    {name}
                </span>
                {this.props.children && 
                <div className="nav-links">
                    {this.props.children}
                </div>}
            </div>    
        );
    }
}
