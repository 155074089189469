import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import './row.scss';
import { RowProps, RowRenderArgs } from '../gridtypes';

@observer
export class Row extends PureComponent<RowProps, {}> {
    render() {
        const { classNamePreffix, selected, onClick, onRowDoubleClick, onHover } = this.props;
        const cls = ['trow'];

        if (classNamePreffix) cls.push(classNamePreffix);
        if (selected) cls.push('selected');

        const attrs: React.HTMLAttributes<HTMLDivElement> & {[key: string]: unknown } = {
            className: cls.join(' '),
            style: this.props.style,
            onClick: onClick,
            onDoubleClick: onRowDoubleClick,
            onMouseDown: onClick,
            onMouseEnter: onHover
        };

        const args: RowRenderArgs = {
            child: this.props.children,
            rowAttributes: attrs
        };

        this.props.onRender?.(args);

        return React.createElement('div', attrs, args.child);
    }
}