import { Checkbox, CheckboxProps } from '@Components';
import React from 'react';
import { Label } from 'reactstrap';
import './_switcher.scss';

// TODO: check diff:
// type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {}

// export type SwitcherProps = React.InputHTMLAttributes<HTMLInputElement> & {
// 	invalid?: boolean;
// 	innerRef?: React.Ref<HTMLInputElement>;
// };

export type SwitcherProps = CheckboxProps;

export class Switcher extends React.PureComponent<SwitcherProps> {
	render() {
		return (
			<Label className="switcher">
				<Checkbox {...this.props} />
				<span/>
			</Label>
		);
	}
}