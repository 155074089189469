import * as React from 'react';
import { TimelineItem } from '@Services';
import { TimelineEventsContainer } from '@Components';
import { RequestTimelineEvent } from './RequestTimelineEvent';

type EventsProps = {
    events: Event[];
    onEventClick: (selectedEvent: TimelineItem) => void;
};

export type Event = {
    width: string;
    left: string;
    count: number;
    text: string;
    item: TimelineItem;
    time: string;
    fullTimeMs: string;
    isException: boolean;
    exception?: string;
    isSelected: boolean;
    backgroundColor?: string;
};

export class RequestTimelineEvents extends React.PureComponent<EventsProps, {}> {
    public render() {
        const { events, onEventClick } = this.props;
        return (
            <TimelineEventsContainer>
                {this.props.children}
                {events.map((event, i) =>
                    <RequestTimelineEvent key={'event' + i} {...event} onEventClick={() => onEventClick(event.item)} />
                )}
            </TimelineEventsContainer>
        );
    }
}
