import * as React from 'react';
import { AxiosError } from 'axios';
import { makeObservable, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';

import { ModalWindow, ModalDialogOptions, IModalDialogContent, ModalButtonType } from '@Components';

type ErrorDialogProps = {
    error?: string[] | string | AxiosError | JSX.Element;
};

@observer
export class ErrorDialog extends React.Component<ErrorDialogProps, {}> implements IModalDialogContent<void> {
    @observable private _errorData: string | null = null;

    public getModalOptions(window: ModalWindow<void>): ModalDialogOptions<void> {
        return {
            title: this.props.error ? 'Request can\'t be completed' : 'Error',
            buttons: [{
                type: ModalButtonType.Ok,
                onClick: () => {
                    window.close();
                }
            }],
            width: '500px'
        };
    }

    constructor(props: ErrorDialogProps) {
        super(props);
        makeObservable(this);
    }
    
    async componentDidMount(){
        const { error } = this.props;

        if (!error) return null;
        if (typeof error !== 'object') return null;
        if (Array.isArray(error)) return null;
        if (React.isValidElement(error)) return null;

        const axiosError = error as AxiosError;
        if (!axiosError.response) {
            return null;
        }

        const data = axiosError.response.data;
        if (data instanceof Blob){
            const text = await new Response(data).text();
            runInAction(() => {
                this._errorData = text;
            });
        } else if ('error' in data){
            this._errorData = data.error;
        } else if ('message' in data){
            this._errorData = data.message;
        } else {
            this._errorData = data;
        }
    }

    render() {
        return (
            <>
                {this._renderMessage()}
                {this._renderRequestMessage()}
            </>
        );
    }

    _renderMessage() {
        const { error } = this.props;
        
        if (typeof error === 'string'){
            return <p>{error}</p>;
        }

        if (Array.isArray(error)) {
            return (
                <>
                    {error.map((mess, index) => <p key={index}>{mess}</p>)}
                </>
            );
        }

        if (!React.isValidElement(error)) return null;

        return error;
    }

    _renderRequestMessage() {
        const { error } = this.props;
        
        const axiosError = error as AxiosError;
        if (!axiosError.isAxiosError || !axiosError.response) {
            return null;
        }

        const errorData = this._errorData;

        return (
            <>
                {errorData && <p>{Array.isArray(errorData) ? errorData.join(' ') : errorData?.toString()}</p>}
                <div>
                    <small>Code: {axiosError.response.status}/{axiosError.response.statusText}</small>
                </div>
                <div>
                    <small>URL: {axiosError.config && axiosError.config.url}</small>
                </div>
            </>
        );
    }
}