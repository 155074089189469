import { observer } from 'mobx-react';
import React from 'react';
import './textarea.scss';

// TODO: check diff:
// type TextareaProps = React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> & {}

export type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
	invalid?: boolean;
	innerRef?: React.Ref<HTMLTextAreaElement>;
};

@observer
export class Textarea extends React.PureComponent<TextareaProps> {
	render() {
		const {innerRef, className, invalid, ...rest} = this.props;
		const cls = ['textarea-wrapper'];

		if (className) cls.push(className);
		if (invalid) cls.push('is-invalid');

		return <textarea {...rest} ref={innerRef} className={cls.join(' ')} />;
	}
}
