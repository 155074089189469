import * as React from 'react';

export type TimelineEventProps = {
    style?: React.CSSProperties;
    classNames?: string[];
    selected?: boolean;
    onClick?: (event: React.MouseEvent<HTMLDivElement,MouseEvent>) => void;
};

export class TimelineEvent extends React.PureComponent<TimelineEventProps, {}> {
    public render() {
        const { style, classNames, onClick } = this.props;
        const cssClasses = classNames ? ['event'].concat(...classNames).join(' ') : 'event';

        return (
            <div
                className={cssClasses}
                style={style}
                onClick={onClick}
            >
                {this.props.children}
            </div>
        );
    }
}