import React from 'react';
import { RiErrorWarningLine } from 'react-icons/ri';
import './error-container.scss';

type ErrorContainerProps = {
	validationErrors?: string[];
};

export class ErrorContainer extends React.Component<ErrorContainerProps> {
	render() {
		const { children, validationErrors } = this.props;

		return (
			<div className="form-element-error-container">
				{children}
				{!!validationErrors?.length && 
				<RiErrorWarningLine
					className="error-icon"
					title={validationErrors.join(' ')}
				/>}
			</div>
		);
	}
}

export const withErrorContainer = <T extends {}>(Component: React.ComponentType<T>) => {
	return class extends React.Component<T & ErrorContainerProps> {
		render () {
			return (
				<ErrorContainer validationErrors={this.props.validationErrors}>
					<Component {...this.props}/>
				</ErrorContainer>
			);
		}
	};
};