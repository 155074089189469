import React from 'react';
import { observer } from 'mobx-react';
import ReactSelect, { GroupTypeBase, OptionTypeBase, Props, StylesConfig } from 'react-select';
import { CSSObject as EmotionCSSObject } from '@emotion/serialize';

export type Option = {
	label: string; 
	value: string;
};

export type SingleSelectProps<OptionType extends OptionTypeBase, GroupType extends GroupTypeBase<OptionType>> = Props<OptionType, false, GroupType> & {
	invalid?: boolean;
};

export type MultiSelectProps<OptionType extends OptionTypeBase, GroupType extends GroupTypeBase<OptionType>> = Props<OptionType, true, GroupType> & {
	invalid?: boolean;
};

//due to conflict of types in Select and emotion/serialize after upgrade to webpack 5 and babel we force to use any to keep unsing current version of select. 
//TODO: Please remove after upgrading 'react-select' package
/* eslint-disable @typescript-eslint/no-explicit-any */
type CSSObject = EmotionCSSObject & any;

function ReactSelectStyles<OptionType extends OptionTypeBase, IsMulti extends boolean, GroupType extends GroupTypeBase<OptionType>>(hasError: boolean): Partial<StylesConfig<OptionType, IsMulti, GroupType>> {
	return {
		control: (base: CSSObject) => ({
			...base,
			...{ minHeight: 'none', borderRadius: 0, fontSize: 12 },
			...(hasError ? { border: '2px solid red' } : { border: base.border })
		}),
		dropdownIndicator: (base: CSSObject) => ({ ...base, padding: '2px' }),
		input: (base: CSSObject) => ({ ...base, lineHeight: '17px' }),
		valueContainer: (base: CSSObject) => ({ ...base, padding: '0 8px' }),
		clearIndicator: (base: CSSObject) => ({ ...base, padding: 0 }),
		multiValue: (base: CSSObject) => ({ ...base, margin: '0 2px 0 0' }),
		menu: (base: CSSObject) => ({ ...base, zIndex: 29, fontSize: 12 }),
		menuPortal: (base: CSSObject) => ({ ...base, zIndex: 99999 }),
		container: (base: CSSObject) => ({...base, width: '100%'})
	};
}

@observer
export class Select<
			OptionType extends OptionTypeBase = { label: string; value: string }, 
			GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
		> extends React.PureComponent<SingleSelectProps<OptionType, GroupType>> {
	render() {
		const { invalid } = this.props;
		
		return (
			<ReactSelect<OptionType, false, GroupType>
				{...this.props}
				styles={{ ...ReactSelectStyles<OptionType, false, GroupType>(!!invalid), ...(this.props.styles || {}) }}
				menuPortalTarget={document.body}
			/>
		);
	}
}

@observer
export class MultiSelect<
		OptionType extends OptionTypeBase = { label: string; value: string }, 
		GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
	> extends React.PureComponent<MultiSelectProps<OptionType, GroupType>> {
	render() {
		const { invalid } = this.props;

		return (
			<ReactSelect<OptionType, true, GroupType>
				{...this.props}
				styles={{ ...ReactSelectStyles<OptionType, true, GroupType>(!!invalid), ...(this.props.styles || {}) }}
				menuPortalTarget={document.body}
				isMulty
			/>
		);
	}
}
