import React, { PureComponent } from 'react';

import './style.scss';

type SyncContainerProps = {
	scrollTop: number;
	viewportHeight: number;
	virtualHeight: number;
	itemHeight: number;
	onScroll: (scrollTop: number) => void;
};

export class SyncContainer extends PureComponent<SyncContainerProps> {
	private _viewportDivRef: React.RefObject<HTMLDivElement> = React.createRef();

	private _onViewportScroll = () => {
		const scrollTop = this._viewportDivRef.current?.scrollTop;
		if (typeof scrollTop !== 'undefined') {
			this.props.onScroll(scrollTop);
		}
	};

	componentDidUpdate(oldProps: SyncContainerProps){
		const { scrollTop } = this.props;
		if (oldProps.scrollTop !== scrollTop && this._viewportDivRef.current){
			this._viewportDivRef.current.scrollTop = scrollTop;
		}
	}

	componentDidMount(){
		if (this._viewportDivRef.current){
			this._viewportDivRef.current.scrollTop = this.props.scrollTop;
		}
	}

    private _getDecimal(num: number): number {
        return num > 0 ? (num % 1) : (-num % 1);
    }

	render(){
		const { viewportHeight, virtualHeight, scrollTop, itemHeight } = this.props;
		const viewportTop = scrollTop - itemHeight * this._getDecimal(scrollTop / itemHeight);
		return (
			<div
				className="outer customized-scroll"
				ref={this._viewportDivRef}
				onScroll={this._onViewportScroll}
				style={{ height: viewportHeight }}
			>
				<div className="virtual-list-vwrapper" style={{height: virtualHeight, position: 'absolute', width: '1px', visibility: 'hidden'}} />
				<div className="inner" style={{ transform: `translateY(${viewportTop}px)`}}>
					{this.props.children}
				</div>
			</div>
		);
	}
};

export const Container: React.FC = ({ children }) => {
	return <div className="scroll-sync-container">{children}</div>;
};
