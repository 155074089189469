import { action, observable, extendObservable, makeObservable } from 'mobx';
import { BaseFormModel, displayName, isRequired } from '@Helpers';
import { AzureRole, AzureRoles } from '@Components';
import { appStore } from '@GlobalStores';
import { ImpersonationDialogProps } from './ImpersonationDialog';

export class ImpersonationStore {
    form: FormModel;
    @observable azureRoles: AzureRole[] = [];
    private email: string;

    constructor(props: ImpersonationDialogProps) {
        makeObservable(this);

        if (props.canImpersonateAsAdmin) {
            this.azureRoles.push(AzureRoles.administrator);
        }

        this.azureRoles.push(AzureRoles.ticketAgent);
        this.azureRoles.push(AzureRoles.expenseAgent);
        this.azureRoles.push(AzureRoles.expenseManager);
        this.azureRoles.push(AzureRoles.support);
        this.azureRoles.push(AzureRoles.powerSupport);


        this.form = new FormModel(this.azureRoles);
    }

    @action
    onEnterImpersonation = (callback: () => void) => () => {
        if (this.form.validate()) {
            let selectedAzureRoles: AzureRole[] = [];
            this.azureRoles.forEach((r) => {
                const name = r.name as keyof FormModel;
                let value = this.form[name];
                if (value === true)
                    selectedAzureRoles.push(r);
            });

            const data = {
                roles: this._distinctListOfRoles(selectedAzureRoles),
                email: this.email
            };

            appStore.setValue('impersonatedUser', data);
            callback();
            window.history.replaceState({}, document.title, window.location.pathname);
            window.location.reload();
        }
    };

    private _distinctListOfRoles(azureRoles: AzureRole[]): string[] {
        let result: string[] = [];
        for (let i = 0; i < azureRoles.length; i++) {
            let roles = azureRoles[i].roles;
            for (let j = 0; j < roles.length; j++) {
                if (result.indexOf(roles[j]) === -1) {
                    result.push(roles[j]);
                }
            }
        }
        return result;
    }
}

export class FormModel extends BaseFormModel<FormModel> {
    @observable
    @isRequired()
    @displayName('roles')
    azureRoles: AzureRole[] = [];

    constructor(parameters: AzureRole[]) {
        super();
        makeObservable(this);
        parameters.forEach((azureRole: AzureRole) => {
            extendObservable(this, {
                [azureRole.name]: false
            });
        });
    }
}