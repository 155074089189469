import React from 'react';
import {observer} from 'mobx-react';

import {PromiseCompletion} from '@Helpers';
import { modalService } from './ModalService';
import { ModalWindow } from './ModalWindow';
import { ModalButtonOptions } from './ModalButton';

export type ModalDialogOptions<T> = {
    buttons?: ModalButtonOptions<T>[];
    loader?: PromiseCompletion;
    title?: string;
    width?: number | string;
    modalClassName?: string;
    bodyClassName?: string;
    disableAutoFocus?: boolean;
    footerPrefix?: JSX.Element;
};

export interface IModalDialogContent<T> {
    getModalOptions: (window: ModalWindow<T>) => ModalDialogOptions<T>;
}

@observer
export class Modals extends React.PureComponent<{}> {
    render() {
        return (<>{modalService.renderModals()}</>);
    }
}
