import * as React from 'react';
import { AzureAuth } from '@Components';
import { FaSignOutAlt } from 'react-icons/fa';
import './logout.scss';

type LogoutProps = {
	className?: string;
};

export class Logout extends React.PureComponent<LogoutProps> {
	constructor(props: {}) {
		super(props);
		this._logOutEventHandler = this._logOutEventHandler.bind(this);
	}

	componentDidMount() {
		window.addEventListener('storage', this._logOutEventHandler);
	}

	componentWillUnmount() {
		window.removeEventListener('storage', this._logOutEventHandler);
	}

	private _logOutEventHandler(event: StorageEvent) {
		if (event.key === 'logout-event') {
			AzureAuth.logOut();
		}
	}

	public render() {
		const { className } = this.props;
		const cls = ['logout'];
		if (className) cls.push(className);

		return (
			<div className={cls.join(' ')}>
				<FaSignOutAlt
					className="icon"
					title="Log out"
					size={14}
					onClick={AzureAuth.logOut}
				/>
			</div>
		);
	}
}