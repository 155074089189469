import React from 'react';
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa';
import './arrow-button.scss';

type ArrowButtonProps = {
	right?: boolean;
	left?: boolean;
	disabled?: boolean;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export const ArrowButton: React.FC<ArrowButtonProps> = ({ right, left, disabled, onClick }) => {
	const Icon = right ? FaCaretRight : left ? FaCaretLeft : null;
	return (
		<button className="arrow-button" onClick={onClick} disabled={disabled}>
			{Icon && <Icon />}
		</button>
	);
};
