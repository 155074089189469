import { PureComponent } from 'react';
import './styles.scss';
import {SyncContainer} from '@Components';

type ScrollerProps<T> = {
	dataSource: T[];
	row: (item: T, index: number) => JSX.Element;
	onScroll: (scrollTop: number) => void;
	settings: ScrollerSettings;
};

export type ScrollerSettings = {
	itemHeight: number;
	scrollTop: number;
	virtualHeight: number;
	viewportHeight: number;
};

export class VirtualScroll<T> extends PureComponent<ScrollerProps<T>> {

	render() {
		const { settings, row, dataSource } = this.props;

		let viewportItemIndex = Math.floor(settings.scrollTop / settings.itemHeight);

        const viewportItemsCount = Math.floor(settings.viewportHeight / settings.itemHeight) + 1;

        const viewportItemIndexFrom = viewportItemIndex;
        let viewportItemIndexTo = viewportItemIndex + viewportItemsCount;

        if (viewportItemIndexTo >= dataSource.length){
			viewportItemIndexTo = dataSource.length - 1;
        }

		const { itemHeight, viewportHeight, scrollTop } = settings;
		
		const reactRows: JSX.Element[] = [];
		for(let i = viewportItemIndexFrom; i <= viewportItemIndexTo; i++){
			reactRows.push(row(dataSource[i], i));
		}

		return (
			<SyncContainer
				onScroll={this.props.onScroll}
				scrollTop={scrollTop}
				viewportHeight={viewportHeight}
				virtualHeight={settings.virtualHeight}
				itemHeight={itemHeight}
			>
				{reactRows}
			</SyncContainer>
		);
	}
}