import React from 'react';
import { withErrorContainer } from '../ErrorContainer/ErrorContainer';
import './input.scss';

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
	invalid?: boolean;
	innerRef?: React.Ref<HTMLInputElement>;
	validationErrors?: string[];
};

export class Input extends React.PureComponent<InputProps> {
	render() {
		const {innerRef, className, invalid, validationErrors, style, value, ...rest} = this.props;
		const cls = ['input-wrapper'];

		if (className) cls.push(className);
		if (invalid || validationErrors?.length) cls.push('is-invalid');

		return (
			<input
				{...rest}
				ref={innerRef}
				className={cls.join(' ')}
				style={style}
				title={value?.toString()}
				value={value}
			/>
		);
	}
}

export const InputWithError = withErrorContainer<InputProps>(Input);