import {ApiService} from './ApiService';

class DownloadService {
    public async downloadFile<T>(url: string, params?: T, sourceFileName?: string) {
        const response = await ApiService.getTypedData<Buffer>(url, params, { responseType: 'blob' });

        const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = objectUrl;
        let fileName = sourceFileName;
        if (!fileName) {
            fileName = '';
            const disposition = response.headers['content-disposition'];
            if (disposition && disposition.indexOf('attachment') !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(disposition);
                if (matches && matches[1]) {
                    fileName = matches[1].replace(/['"]/g, '');
                }
            }
        }
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    }
}

export const downloadService = new DownloadService();