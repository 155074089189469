interface ICallbackFunction extends Function {
	selectTimeOut?: number;
	amountOfCall?: number;
}

export class Extensions {

	executeTimeout(callBack: Function, timeout: number, scope?: unknown, ...args: unknown[]) {
		const extendedFunction = callBack as ICallbackFunction;
		if (extendedFunction.selectTimeOut) {
			clearTimeout(extendedFunction.selectTimeOut);
		}
        extendedFunction.selectTimeOut = window.setTimeout(() => {
			callBack.apply(scope, args);
		}, timeout);
	}

    executeTimeoutWithCounter(callBack: Function, timeout: number, scope?: unknown, ...args: unknown[]) {
        const extendedFunction = callBack as ICallbackFunction;
        if (extendedFunction.selectTimeOut) {
            clearTimeout(extendedFunction.selectTimeOut);
            extendedFunction.amountOfCall = extendedFunction.amountOfCall ? extendedFunction.amountOfCall + 1 : 1;
        }
        extendedFunction.selectTimeOut = window.setTimeout(() => {
            args.push(extendedFunction.amountOfCall || 1);
            extendedFunction.amountOfCall = 0;
            callBack.apply(scope, args);
        }, timeout);
    }

    static getSmsEmail(phone: string): string {
        phone = phone.replace(new RegExp(' ', 'g'), '').replace('+', '00');
        phone += '@msg.ecall.ch';
        return phone;
    }
}

export const extensions = new Extensions();