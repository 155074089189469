import * as React from 'react';
import { Button } from 'reactstrap';
import { FaTimes, FaMinus, FaPlus } from 'react-icons/fa';
import { DateTime } from '@AppConstants';
import DateTimeField from '@1stquad/react-bootstrap-datetimepicker';
import './_time-picker.scss';

export type TimePickerProps = {
	invalid?: boolean;
	validated?: boolean;
	disabled?: boolean;
	
	value: Date | string;
	onControlClick?: Function; // TODO: legacy type - check and restrict
	defaultValue?: Date;
	showReset?: boolean;
	onChange?: (dateTime: Date, timeString: string) => void; // TODO: also check correct usage
};

export class TimePicker extends React.Component<TimePickerProps> {
	render() {
		const { value, disabled, defaultValue, onControlClick, onChange, showReset, invalid, validated } = this.props;
		const step = 30;
		const smallStep = 5;

		let pickerClass = 'time-picker' + (!validated ? ' not-validated' : ' validated');
		if (invalid) {
			pickerClass += ' is-invalid';
		}

		return (
			<div className={pickerClass}>
				<div className="time-picker-node">
					<DateTimeField
						dateTime={value}
						hideInputIcon
						inputFormat={DateTime.timeFormat}
						onChange={onChange}
						mode="time"
						size="sm"
						disabled={disabled}
					/>
				</div>
				<div className="time-picker-controls" hidden={disabled}>
					<Button
						size="xs"
						onClick={(e: React.MouseEvent<HTMLButtonElement>) => onControlClick && onControlClick(value, -((e.shiftKey) ? smallStep : step))}
						color="primary"
					>
						<FaMinus />
					</Button>
					<Button
						size="xs"
						onClick={(e: React.MouseEvent<HTMLButtonElement>) => onControlClick && onControlClick(value, (e.shiftKey) ? smallStep : step)}
						color="primary"
					>
						<FaPlus />
					</Button>
					<Button
						size="xs"
						hidden={!showReset}
						onClick={() => onControlClick && onControlClick(defaultValue, 0)} color="danger"
					>
						<FaTimes />
					</Button>
				</div>
			</div>
		);
	}
}
