export class PersonFunctions {
    public static readonly COCKPIT: string = 'Cockpit';
    public static readonly CABIN: string = 'Cabin';
    public static readonly GROUND: string = 'Ground';
    public static readonly PIC: string = 'PIC';
    public static readonly FO: string = 'FO';
    public static readonly SC: string = 'SC';
    public static readonly CC: string = 'CC';
    public static readonly OPR: string = 'OPR';
    public static readonly OGE: string = 'OGE';
    public static readonly OGGE: string = 'OGGE';
    public static readonly OMCC: string = 'OMCC';
    public static readonly OFC: string = 'OFC';
};

export class PersonContractType {
    public static readonly Fix: string = 'Fix';
    public static readonly Freelance: string = 'Freelance';
    public static readonly Fse: string = 'Fse';
    public static readonly None: string = 'None';
    public static readonly Tzv: string = 'Tzv';
}