import * as React from 'react';
import './_timeline.scss';
import {TimelineHeader, TimeBlockProps} from './Components';

type TimelineProps = {
    timeBlocks: TimeBlockProps[];
    className?: string; 
};

export class Timeline extends React.PureComponent<TimelineProps, {}> {
    //https://codepen.io/MilanMilosev/pen/ONNQJM

    public render() {
        const { timeBlocks, className } = this.props;
        const cls = className ? `timeline ${className}` : 'timeline';

        return (
            <div className={cls}>
                <div id="life">
                    <TimelineHeader timeBlocks={timeBlocks}/>
                    {this.props.children}
                </div>
            </div>
        );
    }
}