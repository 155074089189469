export class SignalREvents {
    public static readonly success: string = 'success';
    public static readonly warning: string = 'warning';
    public static readonly info: string = 'info';
    public static readonly error: string = 'error';

    public static readonly jobStatusChanged: string = 'jobStatusChanged';
    public static readonly jobProgressMaxValueChanged: string = 'jobProgressMaxValueChanged';
    public static readonly jobProgressValueChanged: string = 'jobProgressValueChanged';
    public static readonly sendJobTimeline: string = 'sendJobTimeline';
    public static readonly refreshPersonPeriod: string = 'refreshPersonPeriod';
    public static readonly periodImportProgress: string = 'periodImportProgress';
    public static readonly periodImportStatus: string = 'periodImportStatus';
}

export class ComponentUrls {
    public static readonly HubUrl: string = '/hubs/notification';
    public static readonly HubTokenQueryString: string = '?bearerToken=';
}
export class SignalRGroups {
    public static readonly RequestTimeline: string = ':Internal:REQUEST_TIMELINE';
}
