import * as React from 'react';
import './brand.scss';

type BrandProps = {
	className?: string;
};

export class Brand extends React.PureComponent<BrandProps> {
	render() {
		const { className } = this.props;
		const cls = className ? `${className} main-nav-brand` : 'main-nav-brand';

		return (
			<div className={cls} style={{ backgroundImage: 'url("images/logo.png")' }}>
				{this.props.children}
			</div>
		);
	}
}
