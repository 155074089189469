import { action } from 'mobx';

export class RequestTimer {
    private _timer: number | null;
    private readonly _duration: number = 5000;

    constructor(duration?: number) {
        if (duration) {
            this._duration = duration;
        }
    }

    @action
    public initTimer(initFunction: Function, timeout?: number) {
        this._timer = window.setTimeout(() => {
            const resp = initFunction();
            resp.then(() => {
                this.stop();
                this.initTimer(initFunction, this._duration);
            }).catch(() => {
                this.stop();
                this.initTimer(initFunction, this._duration);
            });
        }, timeout ? timeout : 0);
    }

    @action
    public stop() {
        if (this._timer) {
            window.clearTimeout(this._timer);
            this._timer = null;
        }
    }

}
