import {Component} from 'react';
import {BodyContainer, Modals, NavMenu} from '@Components';
import { securityService } from '@Services';

export class Layout extends Component {
    static displayName = Layout.name;
    
    constructor(props: {}) {
        super(props);
        void securityService.loadPermissions();
    }

    render() {
        return (
            <>
                <NavMenu/>
                <BodyContainer>
                    {this.props.children}
                </BodyContainer>
                <Modals/>
            </>
        );
    }
}
