import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { SalaryPeriodStatus } from '@Models';
import { ModalButtonType, ModalDialogOptions, ModalWindow, NotificationHandler } from '@Components';
import { MonthPicker } from '@Components/MonthPicker/MonthPicker';
import { ReadyToReleaseDialogStore } from './ReadyToReleaseDialogStore';
import { modalService } from '@Components/Modal/ModalService';

type ReadyToReleaseDialogProps = {};


@observer
export default class ReadyToReleaseDialog extends Component<ReadyToReleaseDialogProps, {}> {
    private _store: ReadyToReleaseDialogStore = new ReadyToReleaseDialogStore();

    render() {
        const {setPeriod} = this._store;
        return <MonthPicker nextPeriodBtn={false} pervPeriodBtn={false} onChange={setPeriod} periodStatus={SalaryPeriodStatus.Imported}/>;
    }

    public getModalOptions(window: ModalWindow<void>): ModalDialogOptions<void> {
        return {
            title: 'Confirmation',
            buttons: [{
                type: ModalButtonType.Cancel,
                onClick: () => {
                    window.close();
                }
            }, {
                color: 'success',
                type: ModalButtonType.Confirm,
                onClick: async () => {
                    if (await modalService.showConfirmation('Do you really want to change period status to Ready To Release?')){
                        await this._store.readySelectedPeriod();
                        NotificationHandler.showSuccess('Period changed status to "Ready To Release" successfully');
                        window.close(ModalButtonType.Confirm);
                    }
                }
            }]
        };
    }
}
