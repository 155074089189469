
import React from 'react';
import {observable, action, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {Modal, ModalHeader, ModalFooter } from 'reactstrap';
import Draggable from 'react-draggable';

import {IModalDialogContent, Loading, ModalBodyWithBoundary} from '@Components';
import { ModalButton, ModalButtonOptions, ModalButtonType } from './ModalButton';
import { ModalDialogOptions} from './Modal';
import './modal-window.scss';

export type ModalOptions<T> = {
    title?: string;
    width?: number | string;
    closeByEscape?: boolean;
    dialog: (window: ModalWindow<T>) => JSX.Element;
    buttons?: (window: ModalWindow<T>) => ModalButtonOptions<T>[];
    onAppear?: (window: ModalWindow<T>) => void;
    onHide?: (window: ModalWindow<T>) => void;
};

export type ModalAction = ModalButtonType | string;

type ModalWindowProps<T> = ModalOptions<T> & {
    onClose: (action: ModalAction, result?: T) => void;
};

@observer
export class ModalWindow<T> extends React.Component<ModalWindowProps<T>, {}> {
    private _payload: T | null = null;
    private _focusButton: React.RefObject<HTMLButtonElement> = React.createRef();
    @observable public contentRef: React.RefObject<IModalDialogContent<T>> = React.createRef();

    constructor(props: ModalWindowProps<T>){
        super(props);
        makeObservable(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this._onDocumentKeyDown);
        this.props.onAppear?.(this);
        window.setTimeout(() => this._focusButton.current && this._focusButton.current.focus(), 0);
    }

    componentWillUnmount() {
        this.props.onHide?.(this);
        document.removeEventListener('keydown', this._onDocumentKeyDown);
    }

    render() {
        const {title, dialog, buttons, width} = this.props;
        const content = this.contentRef.current as IModalDialogContent<T>;

        const modalOptions = content?.getModalOptions?.call(content, this);

        const titleToRender = title ?? modalOptions?.title;
        const maxWidth = width ?? modalOptions?.width;
        const widthToRender = typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth;
        const contentButtons = modalOptions?.buttons ?? [];
        const loader = modalOptions?.loader;
        const buttonsToRender = (buttons?.(this) ?? []).concat(contentButtons);
        const leftButtons = buttonsToRender.filter(b => !!b.alignLeft);
        const normalButtons = buttonsToRender.filter(b => !b.alignLeft);
        const footerPrefix = modalOptions?.footerPrefix;
        return (
            <Draggable handle=".modal-header">
                <Modal className={modalOptions?.modalClassName} isOpen={true} backdrop="static" toggle={this._onCloseClick} style={{maxWidth: widthToRender}}>
                    <ModalHeader toggle={this._onCloseClick}>{titleToRender}</ModalHeader>
                    <ModalBodyWithBoundary className={modalOptions?.bodyClassName}>
                        <React.Suspense fallback={<Loading isSuspense/>}>
                            {dialog(this)}
                        </React.Suspense>
                    </ModalBodyWithBoundary>
                    <Loading loading={loader?.isPending}/>
                    {buttonsToRender.length || footerPrefix ? <ModalFooter>
                        {footerPrefix}
                        {leftButtons.length ? <div className="modal-footer-left">
                            {this._renderButtons(leftButtons, modalOptions)}
                        </div> : null}
                        {this._renderButtons(normalButtons, modalOptions)}
                    </ModalFooter> : null}
                </Modal>
            </Draggable>
        );
    }

    private _renderButtons(buttons: ModalButtonOptions<T>[], options?: ModalDialogOptions<T>) {
        return buttons.map((b, index) => {
            let isFocused = buttons.length === 1 && !options?.disableAutoFocus;
            if (typeof b.setFocus === 'boolean') {
                isFocused = b.setFocus;
            } else if (!isFocused && !options?.disableAutoFocus) {
                const activeButtons = buttons.filter(b => b.type !== ModalButtonType.Cancel && b.type !== ModalButtonType.Close);
                if (activeButtons.length === 1) {
                    isFocused = activeButtons[0].type === b.type;
                }
            }
            return (<ModalButton<T> innerRef={isFocused ? this._focusButton : void 0} key={`${b.type}${index}`} {...b} window={this}/>);
        });
    }

    @action.bound
    private _onDocumentKeyDown(event: KeyboardEvent) {
        const VK_ESCAPE = 27;
        const {closeByEscape} = this.props;
        if (event.keyCode === VK_ESCAPE && closeByEscape !== false) {
            this.props.onClose(ModalButtonType.Cancel, undefined);
        }
    }

    @action.bound
    private _onCloseClick() {
        this.props.onClose(ModalButtonType.Cancel, undefined);
    }

    public close(button: ModalAction = ModalButtonType.Close, result?: T) {
        this.props.onClose(button, result || this._payload || void 0);
    }

    public getPayload(): T | null {
        return this._payload;
    }

    public setPayload(payload: T | null) {
        this._payload = payload;
    }
}
