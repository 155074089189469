import { SalaryPeriodResponseItem, UserProfile } from '@Models';
import { observable, action, makeObservable } from 'mobx';

class AppStore {
    @observable isLoading: boolean = false;
    ajaxCount: number = 0;
    @observable
    public timePeriod: SalaryPeriodResponseItem | null = null;

    constructor() {
        makeObservable(this);
    }

    public get currentToken(): string {
        return this.getValue<string>('currentToken') || '';
    }

    public get currentUser(): UserProfile | null {
        return this.getValue<UserProfile>('currentUser');
    }

    get currentUserName() {
        if (!this.currentUser){
            return 'Guest';
        }

        const { name, family_name, given_name, email, unique_name } = this.currentUser;

        if (name){
            return name;
        }

        if (given_name || family_name){
            return `${given_name} ${family_name}`.trim();
        }

        return email || unique_name;
    }

    @action
    showLoading() {
        this.ajaxCount++;
        if (!this.isLoading) {
            this.isLoading = true;
        }
    }

    @action
    hideLoading() {
        this.ajaxCount--;
        if (this.ajaxCount === 0) {
            this.isLoading = false;
        }
    }

    @action
    isAuthorized(): boolean {
        return !!(this.currentToken && this.currentUser);
    }

    public get impersonatedUser(): IImpersonationModel | null {
        return this.getValue<IImpersonationModel>('impersonatedUser');
    }

    public get currentUserRoles(): string[] {
        return (this.impersonatedUser && this.impersonatedUser.roles) || (this.currentUser && this.currentUser.roles) || [];
    }

    public get realUserRoles(): string[] {
        return (this.currentUser && this.currentUser.roles) || [];
    }

    @action
    clearUserData() {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentToken');
        localStorage.removeItem('impersonatedUser');
        localStorage.removeItem('homeViewState');
    }

    @action
    setValue<T>(key: string, value: T) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    getValue<T>(key: string): T | null {
        const value = localStorage.getItem(key);
        return value ? JSON.parse(value) as T : null;
    }

    @action.bound
    setPeriodFilter(timePeriod: SalaryPeriodResponseItem | null) {
        this.timePeriod = timePeriod;
    }
}

interface IImpersonationModel {
    roles?: Array<string>;
}

export const appStore = new AppStore();