import * as React from 'react';

export class TimelineEventsContainer extends React.PureComponent<{}, {}> {
    public render() {
        return (
            <div className="events">
                {this.props.children}
            </div>
        );
    }
}