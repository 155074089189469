import React, { Component } from 'react';
import { action, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { AxiosError } from 'axios';
import { AiOutlineWarning } from 'react-icons/ai';

import { ImportTypeModel, PermissionType, SalaryPeriodResponseItem, SalaryPeriodStatus } from '@Models';
import { ApiUrls, IMPORT_FINISHED } from '@AppConstants';

import { ImportDialogStore } from './ImportDialogStore';
import { securityService } from '@Services';
import { appStore } from '../../Stores';
import { getEncodedToken } from '@Helpers';

import { MonthPicker } from '@Components/MonthPicker/MonthPicker';
import { ModalButtonType, ModalDialogOptions, ModalWindow, NotificationHandler } from '@Components';
import { ChooseFileButton } from './Components/ChooseFileButton/ChooseFileButton';
import { FileRow } from './Components/FileRow/FileRow';

import './import-dialog.scss';

type ImportDialogProps = {
    defaultPeriod: SalaryPeriodResponseItem;
    showPeriodPicker?: boolean;
    importType: ImportTypeModel;
};

@observer
export default class ImportDialog extends Component<ImportDialogProps, {}> {
    public periodId: number;
    public periodStatus: SalaryPeriodStatus = SalaryPeriodStatus.Released;
    public files: File[] = [];
    public fileInputRef = React.createRef<HTMLInputElement>();
    public progress: number = 0;
    public errors: string[] = [];
    public loading: boolean = false;
    public showWarning: boolean = true;
    private _store: ImportDialogStore;
    private readonly _isSingleFileMode = true;
    private readonly _acceptedFormats: string[] = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
    private readonly _maxFileSize: number = 1024 * 1024 * 50;

    constructor(props: ImportDialogProps) {
        super(props);
        this.periodId = props.defaultPeriod.id;
        this._store = new ImportDialogStore(props.importType.id);

        makeObservable(this, {
            _onFileChanged: action.bound,
            _handleDelete: action.bound,
            _handleCancel: action.bound,
            files: observable,
            periodStatus: observable,
            fileInputRef: observable,
            progress: observable,
            errors: observable,
            loading: observable,
            periodId: observable,
            showWarning: observable
        });
    }

    @computed
    public get isImportDisabled() {
        const periodStatus = this.props.showPeriodPicker ? this.periodStatus : this.props.defaultPeriod.status;
        const isDisabled = periodStatus === SalaryPeriodStatus.Released || (periodStatus === SalaryPeriodStatus.ReadyToRelease && !securityService.hasPermission(PermissionType.ReleaseImportPeriod));

        return this.files.length < 1 || isDisabled;
    }

    public getModalOptions(window: ModalWindow<void>): ModalDialogOptions<void> {
        return {
            buttons: [
                {
                    type: ModalButtonType.Cancel,
                    onClick: () => window.close()
                },
                {
                    type: ModalButtonType.Save,
                    title: 'Import',
                    isDisabled: this.isImportDisabled,
                    onClick: async () => {
                        if (!this.files.length) return;
                        if (this._isSingleFileMode) {
                            try {
                                this.loading = true;
                                this.progress = 70;
                                await this._store.uploadFile(this.periodId, this.files[0]);
                                this.files = [];
                                this.progress = 100;
                                NotificationHandler.showSuccess('The file is imported succesfully');
                                document.dispatchEvent(new Event(IMPORT_FINISHED));
                                window.close();
                            } catch (e) {
                                const axiosError = e as AxiosError;

                                const errors = [];
                                if (typeof axiosError.response?.data?.message === 'string'){
                                    errors.push(axiosError.response?.data?.message);
                                }

                                if (Array.isArray(axiosError.response?.data)){
                                    errors.push(...axiosError.response?.data as string[]);
                                }

                                if (!errors.length) {
                                    errors.push(axiosError.message);
                                }

                                this.errors = errors;
                            } finally {
                                this.loading = false;
                            }
                        }
                    }
                }
            ],
            width: '500px',
            bodyClassName: 'create-rule-dialog'
        };
    }

    render() {
        const encodedToken = getEncodedToken(appStore.currentToken);
        const sampleUrl = ApiUrls.SalaryImportSampleUrl + `?importType=${this.props.importType.id}&token=${encodeURIComponent(encodedToken)}`;

        return (
            <div>
                <p className="import-warning">
                    <AiOutlineWarning size={20} />
                    Warning! Import process can overwrite existing info
                </p>
                {this.props.showPeriodPicker && (
                    <div className="import-month-picker">
                        <MonthPicker
                            defaultPeriod={this.props.defaultPeriod}
                            onChange={(period) => { this.periodId = period?.id || 0; this.periodStatus = period?.status || SalaryPeriodStatus.Released; }}
                        />
                    </div>
                )}
                {!this.props.showPeriodPicker && <p>The period will be taken from the imported file.</p>}
                {this.files.map((file, index) =>
                    <FileRow
                        key={`${file.name}_${index}`}
                        uploading={this.loading}
                        progressValue={this.progress}
                        fileName={file.name}
                        errors={this.errors}
                        onCancel={() => this._handleCancel(index)}
                        onDelete={() => this._handleDelete(index)}
                    />
                )}
                <div className="import-section">
                    <div>
                        <input
                            ref={this.fileInputRef}
                            multiple={!this._isSingleFileMode}
                            type="file"
                            accept={this._acceptedFormats.join(',')}
                            onChange={this._onFileChanged}
                            className="file-input"
                        />
                        {
                            this.files.length === 0 &&
                            <ChooseFileButton onClick={this._handleAddClick} />
                        }
                        {
                            this.files.length === 0 &&
                            <span className="text"> or get a<a href={sampleUrl} target="_blank" rel="noreferrer" > sample.</a></span>
                        }
                    </div>
                </div>
            </div>
        );
    }

    private _handleAddClick = () => {
        const refInput = this.fileInputRef.current;

        if (refInput) {
            refInput.value = '';
            refInput.click();
        }
    };

    public _handleDelete(index: number) {
        this.files.splice(index, 1);
        this.errors = [];
    }

    public _handleCancel(index: number) {
        this.files.splice(index, 1);
    }

    public _onFileChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        this.errors = [];
        const files = Array.from(event.target.files);

        if (files && files.length) {
            const byFormat = (file: File) => this._acceptedFormats.indexOf(file.type) > -1;
            const bySize = (file: File) => file.size <= this._maxFileSize;

            const acceptedFiles = files.filter(byFormat).filter(bySize);
            if (acceptedFiles.length) {
                this.files = this._isSingleFileMode ? [acceptedFiles[0]] : [...this.files, ...acceptedFiles];
            }
        }
    };
}
