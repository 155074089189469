import React from 'react';
// import './input.scss';

// TODO: check diff:
// type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {}

export type RadioProps = React.InputHTMLAttributes<HTMLInputElement> & {
	invalid?: boolean;
	innerRef?: React.Ref<HTMLInputElement>;
};

export class Radio extends React.PureComponent<RadioProps> {
	render() {
		const {innerRef, className, invalid, ...rest} = this.props;
		const cls = [
			// 'input-wrapper'
			'radio-wrapper'
		];

		if (className) cls.push(className);
		if (invalid) cls.push('is-invalid');

		return <input {...rest} ref={innerRef} className={cls.join(' ')} type="radio"/>;
	}
}