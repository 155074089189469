import { ApiClient, FileParameter } from '@Models';
import { makeAutoObservable } from 'mobx';
import { IAjaxOptions } from '../../Services';


export class ImportRulesStore {
    private _apiClient = new ApiClient();

    constructor() {
        makeAutoObservable(this);
    }

    public async  uploadFile(file: File) {
        const param: FileParameter = {
            data: file,
            fileName: file.name
        };

        const options: IAjaxOptions = {
            suppressErrorHandling: true,
            customApiHeader: { 'content-type': 'multipart/form-data' }

        };
        await this._apiClient.ruleUploadPost(param, options);
    }
}