import React, {ClassType, FunctionComponent} from 'react';
import {observable, computed, makeObservable, action} from 'mobx';
import {AxiosError} from 'axios';
import {ConfirmationDialog, InformationDialog, ErrorDialog} from '@Components';
import { ModalAction, ModalOptions, ModalWindow } from './ModalWindow';
import { ModalDialogOptions } from './Modal';
import { ButtonColor, ModalButtonType } from './ModalButton';

type ConfirmationOptions = {
    title?: string;
    color?: ButtonColor;
};

export type ModalResult<T> = {
    button: ModalAction;
    result: T | null;
};

type ModalWindowProps<T> = ModalOptions<T> & {
    onClose: (action: ModalAction, result?: T) => void;
};

type ModalPromise<T> = Promise<ModalResult<T>> & {
    close: (action?: ModalAction, result?: T) => void;
};

class ModalService {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @observable private _modalData: ModalWindowProps<any>[] = [];

    constructor(){
        makeObservable(this);
    }

    @computed
    public get hasActiveModal() {
        return !!this._modalData.length;
    }

    public renderModals = () => {
        return this._modalData.map((m, index) => <ModalWindow key={'m' + index} {...m} />);
    };

    public show<P extends {}, T>(dialog: ClassType<P, React.Component<P, {}, {}>, React.ComponentClass<P, {}>> | FunctionComponent<P>, props?: P, options?: Omit<ModalDialogOptions<T>, 'dialog'>): ModalPromise<T> {
        return this.showModal(Object.assign({}, {
            dialog: (window: ModalWindow<T>) => {
                const content = React.createElement(dialog, Object.assign({}, props, {ref: window.contentRef}));
                return content;
            }
        }, options) as ModalOptions<T>);
    }

    @action
    public showModal<T>(options: ModalOptions<T>): ModalPromise<T> {
        let data: ModalWindowProps<T> | null = null;
        const result = new Promise<ModalResult<T>>((resolve) => {
            data = Object.assign(options, {
                onClose: (button: ModalAction, result?: T) => {
                    resolve({
                        button: button,
                        result: result || null
                    });
                    const modalIndex = this._modalData.findIndex(d => d === data);
                    if (modalIndex !== -1) {
                        this._modalData.splice(modalIndex, 1);
                    }
                    if (!this._modalData.length) {
                        document.body.classList.remove('modal-open');
                    }
                }
            });
            if (!this._modalData.length) {
                document.body.classList.add('modal-open');
            }
            this._modalData.push(data);
            data = this._modalData[this._modalData.length - 1];
        });

        return Object.assign(result, {
            close: (action?: ModalAction, result?: T) => data?.onClose(action || ModalButtonType.Ok, result)
        });
    }

    public async showConfirmation(message: string | string[] | JSX.Element, titleOrOptions?: string | ConfirmationOptions, options?: ConfirmationOptions) {
        const confirmationOptions = typeof titleOrOptions === 'object' ? titleOrOptions : options;
        const result = await this.show(ConfirmationDialog, {
            message: message,
            title: typeof titleOrOptions === 'string' ? titleOrOptions : titleOrOptions?.title,
            color: confirmationOptions?.color || 'primary'
        });

        return result.button === ModalButtonType.Confirm;
    }

    public async showInformation(message: string | string[] | JSX.Element, title?: string, options?: { className?: string }) {
        await this.show(InformationDialog, {
            message: message,
            title: title,
            className: options?.className
        });
    }

    public showError = async (errorContent: string | string[] | JSX.Element | AxiosError) => {
        await this.show(ErrorDialog, {
            error: errorContent
        });
    };
}

export const modalService = new ModalService();