import { action, observable } from 'mobx';

class ApiRequestStore {

    @observable private _timestamps: Map<string, string> = new Map<string, string>();

    @action
    get(key: string): string | undefined {
        return this._timestamps.get(key);
    }

    @action
    set(key: string, value: string) {
        this._timestamps.set(key, value);
    }

    @action
    delete(key: string) {
        this._timestamps.delete(key);
    }

    has(key: string): boolean {
        return this._timestamps.has(key);
    }
}

export const apiRequestStore = new ApiRequestStore();