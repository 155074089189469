import React from 'react';
import './form-feedback.scss';

export class FormFeedback extends React.PureComponent {
	render() {
        return (
            <div className="form-feedback">
                {this.props.children}
            </div>
        );
	}
}