import { observer } from 'mobx-react';
import React, { Component } from 'react';

import './release-period-dialog.scss';

import { ReleasePeriodDialogStore } from './ReleasePeriodDialogStore';
import { modalService } from '@Components/Modal/ModalService';
import { MonthPicker } from '@Components/MonthPicker/MonthPicker';
import { Loading, ModalButtonType, ModalDialogOptions, ModalWindow, NotificationHandler } from '@Components';
import { SalaryPeriodStatus } from '@Models';
import { DutyCheckStore } from '@Pages/Home/Components/DutyStatus/DutyCheckStore';

type ReleasePeriodDialogProps = {};

@observer
export default class ReleasePeriodDialog extends Component<ReleasePeriodDialogProps, {}> {
    private _store: ReleasePeriodDialogStore = new ReleasePeriodDialogStore();
    private _dutyCheckStore: DutyCheckStore = new DutyCheckStore();

    render() {
        const { setPeriod } = this._store;
        return (
            <div className="release-period-dialog">
                <MonthPicker
                    nextPeriodBtn={false}
                    pervPeriodBtn={false}
                    onChange={async (period) => {
                        setPeriod(period);
                        if (!period) {
                            return;
                        }
                        await this._dutyCheckStore.checkPeriodAsync(period.id);
                    }}
                    periodStatus={SalaryPeriodStatus.ReadyToRelease}
                />
                <div className="duty-check-status">
                    <Loading loading={this._dutyCheckStore.completion.isPending} small={true} />
                </div>
            </div>
        );
    }

    public getModalOptions(window: ModalWindow<void>): ModalDialogOptions<void> {
        return {
            title: 'Confirmation',
            buttons: [{
                type: ModalButtonType.Cancel,
                onClick: () => {
                    window.close();
                }
            }, {
                title: 'Reject',
                color: 'danger',
                type: ModalButtonType.Reset,
                onClick: async () => {
                    if (await modalService.showConfirmation('Do you really want to reject period?')) {
                        await this._store.rejectSelectedPeriod();
                        NotificationHandler.showSuccess('Period rejected successfully');
                        window.close(ModalButtonType.Reset);
                    }
                }
            }, {
                color: 'success',
                type: ModalButtonType.Confirm,
                isDisabled: this._dutyCheckStore.completion.isPending,
                onClick: async () => {
                    let message;
                    const unimportedCount = this._dutyCheckStore.unfilledPersons.length;
                    if (unimportedCount> 0) {
                        message = `Do you really want to release period with ${unimportedCount} unimported persons`;
                    } else {
                        message = 'Do you really want to release period?';
                    }
                    if (await modalService.showConfirmation(message)) {
                        await this._store.releaseSelectedPeriod();
                        NotificationHandler.showSuccess('Period released successfully');
                        window.close(ModalButtonType.Confirm);
                    }
                }
            }]
        };
    }
}
