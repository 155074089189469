import * as React from 'react';

import {DateTimeService, RequestTimelineData, TimelineItem} from '@Services';
import {Timeline, TimelineEvent} from '@Components';
import {RequestTimelineEvents} from './Components';
import './_request-timeline.scss';

type RequestTimelineProps = {
    requestTimelineData: RequestTimelineData;
    selectedEvent?: TimelineItem;
    onSelectEvent: (selectedEvent: TimelineItem) => void;
};

export class RequestTimeline extends React.PureComponent<RequestTimelineProps, {}> {

    public render() {
        const {onSelectEvent, requestTimelineData} = this.props;

        const {TotalTimeMs, StartUtc, TimelineParameters, TimelineName} = requestTimelineData;
        const timeRanges = [10, 20, 50, 100, 200, 350, 500, 1000, 2000, 5000, 10000, 30000, 60000];
        const timeSplits = [10, 10, 10, 10, 10, 14, 10, 10, 20, 10, 10, 30, 20];

        let maxTime = 0;
        let splits = 0;

        for (let i = 0; i < timeRanges.length; i++) {
            maxTime = timeRanges[i];
            splits = timeSplits[i];

            if (timeRanges[i] * 0.7 > TotalTimeMs) {
                break;
            }
        }

        const msLength = 100 * (1 / maxTime);
        const headerTimeBlocks = this._getHeaderTimeBlocks(splits, maxTime, msLength);
        const events = this._getEvents(msLength);
        const width = (TotalTimeMs * msLength).toFixed(2).toString() + '%';
        const dateTimeStartUtc = DateTimeService.fromString(StartUtc);

        //https://codepen.io/MilanMilosev/pen/ONNQJM
        return (
            <Timeline timeBlocks={headerTimeBlocks} className="request-timeline">
                <RequestTimelineEvents events={events} onEventClick={onSelectEvent}>
                    <TimelineEvent key={'request'}>
                        <div className="event-pre">
                            <b>{TimelineParameters}</b>
                            {TimelineName}
                        </div>
                        <div className="time" style={{width}}/>
                        <b>{TotalTimeMs.toFixed(1)}ms</b>
                        {DateTimeService.toUiClientShortDateTime(dateTimeStartUtc)}
                    </TimelineEvent>
                </RequestTimelineEvents>
            </Timeline>
        );
    }

    private _getHeaderTimeBlocks(splits: number, maxTime: number, msLength: number) {
        const headerTimeBlocks = [];
        const splitValue = maxTime / splits;

        for (let i = 0; i < splits; i++) {
            const ms = splitValue * i;
            const left = (ms * msLength).toFixed(2).toString() + '%';
            const title = ms > 1000 ? (ms / 1000).toFixed(1) + 's' : ms.toFixed(1);
            headerTimeBlocks.push({left, title});
        }
        return headerTimeBlocks;
    }

    private _getEvents(msLength: number) {
        const {requestTimelineData: data, selectedEvent} = this.props;

        const events = [];
        const repeatCounts = new Map<string, number>();
        const repeatFullTime = new Map<string, number>();

        for (let i = 0; i < data.Items.length; i++) {
            const {Text, OperationName, EndTimeMs, StartTimeMs} = data.Items[i];
            const eventKey = Text || OperationName;

            if (!eventKey) continue;

            let fullTimeMs = repeatFullTime.get(eventKey) || 0;
            fullTimeMs += EndTimeMs - StartTimeMs;
            repeatFullTime.set(eventKey, fullTimeMs);

            let count = repeatCounts.get(eventKey) || 0;
            count++;
            repeatCounts.set(eventKey, count);
        }

        for (let i = 0; i < data.Items.length; i++) {
            const {StartTimeMs, EndTimeMs, CssColor, Text, OperationName, IsException: isException, ExceptionMessage: exception} = data.Items[i];
            const item = data.Items[i];

            const left = (StartTimeMs * msLength).toFixed(2).toString() + '%';
            const width = ((EndTimeMs - StartTimeMs) * msLength).toFixed(2).toString() + '%';
            const backgroundColor = CssColor ? CssColor : void 0;

            const eventKey = Text || OperationName || '';
            const count = eventKey ? repeatCounts.get(eventKey) || 0 : 0;
            const time = (EndTimeMs - StartTimeMs).toFixed(1);
            const fullTimeMs = (eventKey ? repeatFullTime.get(eventKey) || 0 : 0).toFixed(1);
            const isSelected = !!(selectedEvent && (selectedEvent.OperationName || selectedEvent.Text) === (OperationName || Text));

            events.push({width, left, backgroundColor, count, time, fullTimeMs, text: eventKey, item, isException: !!isException, exception, isSelected});
        }
        return events;
    }
}