import * as React from 'react';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {SignalREvents} from '@AppConstants';
import {signalRService} from '@Services';

export class NotificationContainer extends React.Component {
    render() {
        return <ToastContainer position="bottom-right"/>;
    }
}

export class NotificationHandler {

    static subscribeToNotifications() {
        signalRService.subscribe<string>(SignalREvents.success, (eventName: string, message: string) => NotificationHandler.showSuccess(message));
        signalRService.subscribe<string>(SignalREvents.warning, (eventName: string, message: string) => NotificationHandler.showWarning(message));
        signalRService.subscribe<string>(SignalREvents.error, (eventName: string, message: string) => NotificationHandler.showError(message));
        signalRService.subscribe<string>(SignalREvents.info, (eventName: string, message: string) => NotificationHandler.showInfo(message));
    }

    public static showError(message: string, toastId?: string) {
        toast.error(message, {toastId, pauseOnFocusLoss: false, closeOnClick: true, position: 'bottom-left' });
    }

    public static showInfo(message: string, toastId?: string) {
        toast.info(message, {toastId, pauseOnFocusLoss: false, closeOnClick: true, position: 'bottom-left'});
    }

    public static showWarning(message: string, toastId?: string) {
        toast.warning(message, {toastId, pauseOnFocusLoss: false, closeOnClick: true, position: 'bottom-left'});
    }

    public static showSuccess(message: string, toastId?: string) {
        toast.success(message, {toastId, pauseOnFocusLoss: false, closeOnClick: true, position: 'bottom-left'});
    }

    public static clear() {
        toast.clearWaitingQueue();
    }
}

NotificationHandler.subscribeToNotifications();