import React from 'react';
import { Button, Progress } from 'reactstrap';
import { FaStop, FaTrash } from 'react-icons/fa';
import { RiFileExcel2Fill } from 'react-icons/ri';
import './file-row.scss';

type FileRowProps = {
    fileName: string;
    errors: string[];
    uploading: boolean;
    progressValue?: number;
    onCancel: () => void;
    onDelete: () => void;
};

const FileRow = (props: FileRowProps) => {
    const { fileName, uploading, progressValue, errors, onCancel, onDelete } = props;
    return (
        <div className="file-row">
            <div className="file-row-name">
                <div>   
                    <RiFileExcel2Fill color="#1f6e43" size={20}/>
                    {fileName}
                </div>
                <div>
                    {uploading && 
                    <Button size="sm" color="danger" onClick={onCancel} title="cancel" >
                        <FaStop size={12}/>
                    </Button>}

                    {!uploading &&
                    <Button size="sm" color="danger" onClick={onDelete} title="remove">
                        <FaTrash size={12}/>
                    </Button> }
                </div>
            </div>
            {uploading && <Progress color="info" value={progressValue}  striped/>}
            {!!errors.length &&  errors.map((err, i) => <span key={i} className="import-error">{err}</span>)}
        </div>
    );
};

export default React.memo(FileRow);