import * as React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';

import {ImportSalaryType} from '@AppConstants';
import { appStore, devInfoStore, ImportTypesStore, navigationStore } from '@GlobalStores';
import { securityService } from '@Services';
import { PermissionType } from '@Models';
import { modalService } from '@Components/Modal/ModalService';

import ImportDialog from '../../Pages/Import/ImportDialog';
import ImportPeriodDialog from '@Pages/ImportPeriodDialog/ImportPeriodDialog';
import ReadyToReleaseDialog from '@Pages/ReadyToReleaseDialog/ReadyToReleaseDialog';
import ReleasePeriodDialog from '@Pages/ReleasePeriodDialog/ReleasePeriodDialog';
import { Brand, NavGroup, Version, HelpLink, UserName, Logout, Impersonation } from './Components';

import './_main-nav.scss';
import ImportRulesDialog from '../../Pages/ImportRules/ImportRulesDialog';

@observer
class NavMenu extends React.PureComponent {
    private _importTypeStore: ImportTypesStore;
    // private _helpLink: string = 'http://google.com';
    private _helpLink: string = '';

    constructor(props: {}) {
        super(props);
        this._importTypeStore = new ImportTypesStore();
    }

    componentDidMount() {
        void this._importTypeStore.loadImportTypes();
    }

    render() {
        const { importTypes } = this._importTypeStore;
        const showTools = securityService.hasAnyPermission([
            PermissionType.ViewServiceTools,
            PermissionType.ProcessImportPeriod,
            PermissionType.ReleaseImportPeriod,
            PermissionType.EditTickets,
            PermissionType.EditTicketRefunds,
            PermissionType.EditDutyFree,
            PermissionType.EditShiftBonus,
            PermissionType.ReadyToReleasePeriod
        ]);

        const showRuleEditor = securityService.hasPermission(PermissionType.ViewRule);
        const allowEditRule = securityService.hasPermission(PermissionType.EditRule);
        const { appEnvironment, appVersion, appName } = devInfoStore;

        return (
            <div className="main-nav">
                <Brand className="nav-item">
                    {this.renderLink('/', '')}
                </Brand>
                <NavGroup
                    className="nav-item"
                    name={this.renderLink('/', 'Home')}
                />
                {showTools &&
                    <NavGroup
                        className="nav-item"
                        name={'Tools'}
                    >
                        {securityService.hasPermission(PermissionType.ProcessImportPeriod) &&
                            <NavLink
                                target='_self'
                                className={'nav-link'}
                                onClick={async () => await modalService.show(ImportPeriodDialog, {}, { title: 'Import Period ' })}
                            >
                                Import Period
                            </NavLink>}
                        {securityService.hasPermission(PermissionType.ReadyToReleasePeriod) &&
                            <NavLink
                                target='_self'
                                className={'nav-link'}
                                onClick={async () => await modalService.show(ReadyToReleaseDialog, {}, { title: 'Ready to Release Period' })}
                            >
                                Ready to Release Period
                            </NavLink>}
                        {securityService.hasPermission(PermissionType.ReleaseImportPeriod) &&
                            <NavLink
                                target='_self'
                                className={'nav-link'}
                                onClick={async () => await modalService.show(ReleasePeriodDialog, {}, { title: 'Release Period' })}
                            >
                                Release Period
                            </NavLink>}
                        {securityService.hasPermission(PermissionType.ViewServiceTools) && this.renderLink('/health-board', 'Health Board')}
                        {securityService.hasPermission(PermissionType.ViewServiceTools) && this.renderLink('/job-list', 'Job List')}
                        {securityService.hasPermission(PermissionType.ViewServiceTools) && this.renderLink('/configs', 'Configs')}
                        <div className='nav-link-separator' />
                        {importTypes.map(it => this.renderImportSalaryModalDialogLink(it.name, it.id))}
                        {allowEditRule && <div className='nav-link-separator' />}
                        {allowEditRule && this.renderImportRulesModalDialogLink()}
                    </NavGroup>}

                {showRuleEditor && <NavGroup className="nav-item" name={this.renderLink('/rule-editor', 'Rule Editor')} />}

                <Version
                    className="nav-item"
                    appEnvironment={appEnvironment}
                    appVersion={appVersion}
                    appName={appName}
                />

                <div className="nav-item space" />

                {this._helpLink && <HelpLink className="nav-item" link={this._helpLink} />}

                <div className="nav-item">
                    <UserName name={appStore.currentUserName} />
                </div>

                <Impersonation className="nav-item" />
                <Logout className="nav-item" />
            </div>
        );
    }

    static _viewClass(isActive?: boolean) {
        return 'nav-link' + (isActive ? ' active-view' : '');
    }

    renderLink = (toUrl: string, title: string, openInNewTab?: boolean, id?: string) => {
        const isActive = navigationStore.currentLocation && navigationStore.currentLocation.pathname === toUrl;
        return <Link to={toUrl} target={openInNewTab ? '_blank' : '_self'} className={'nav-link' + (isActive ? ' active' : '')} id={id}>{title}</Link>;
    };

    renderImportSalaryModalDialogLink = (title: string, id: number) => {
        const showPeriodPicker = id !== ImportSalaryType.dutyFree;
        return (
            <NavLink
                target='_self'
                className={'nav-link'}
                key={id.toString()}
                onClick={async () => {
                    if (!appStore.timePeriod) return;

                    const props = {
                        defaultPeriod: appStore.timePeriod,
                        importType: { id: id, name: title },
                        showPeriodPicker: showPeriodPicker
                    };
                    return await modalService.show(ImportDialog, props, { title: `Upload ${title}` });
                }}
            >
                {title}
            </NavLink>
        );
    };

    renderImportRulesModalDialogLink = () => {
        const title = 'Import Rules';
        return (
            <NavLink
                target='_self'
                className={'nav-link'}
                key={'importRules'}
                onClick={async () => {
                    return await modalService.show(ImportRulesDialog, {}, { title });
                }}
            >
                {title}
            </NavLink>
        );
    };
}

export { NavMenu };
