import * as React from 'react';
import {TimeBlock, TimeBlockProps} from '@Components';

export type TimelineHeaderProps = {
    timeBlocks: TimeBlockProps[];
};

export class TimelineHeader extends React.PureComponent<TimelineHeaderProps, {}> {
    public render() {
        const { timeBlocks } = this.props;
        return (
            <>
                {timeBlocks.map((tb, i) =>
                    <TimeBlock key={'header' + i} left={tb.left} title={tb.title}/>
                )}
            </>
        );
    }
}

