import { CellData } from '../gridtypes';

export const defaultCellRender = <T extends unknown>(cellData: CellData<T, unknown>) => {
	return <>{cellData.value || null}</>;
};

export const defaultHeaderCellRender = <T extends unknown>(cellData: CellData<T, unknown>) => {
    return <>{cellData.caption || null}</>;
};

export const cellRenderer = <T extends unknown>(cellData: CellData<T, unknown>) => {
    const { row, dataField } = cellData;

    if (!dataField || !row) {
        return null;
    }

    const value = row[dataField] || null;
    return <>{value}</>;
};