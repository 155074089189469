import * as React from 'react';

export type TimeBlockProps = {
    left: string;
    title?: string;
};

export class TimeBlock extends React.PureComponent<TimeBlockProps, {}> {
    public render() {
        const { left, title: header } = this.props;

        return (
            <div className="time-block" style={{ left }}>
                {header}
            </div>
        );
    }
}