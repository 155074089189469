import {ChangeEvent} from 'react';
import {makeAutoObservable} from 'mobx';
import {ApiClient, IImportParams, ISalaryPeriodResponseItem} from '@Models';
import {PeriodProgressEvent} from '@Components/MonthPicker/MonthPicker';


export class ImportPeriodDialogStore {
    private _apiClient = new ApiClient();
    public allowOverride: boolean = false;
    public period: ISalaryPeriodResponseItem | null = null;
    public importPeriod: ISalaryPeriodResponseItem | null = null;
    public progress: PeriodProgressEvent | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    public updateProgress(progress: PeriodProgressEvent) {
        this.progress = progress.text?.toLowerCase() === 'done' ? null : progress;
    }

    public onPeriodChange = (period: ISalaryPeriodResponseItem | null) => {
        this.period = period;
    };

    public onOverrideChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.allowOverride = e.target.checked;
    };

    public async importSelectedPeriod() {
        let params: IImportParams =
            {
                periodId: this.period?.id ?? 0,
                allowOverride: this.allowOverride
            };
        this.importPeriod = this.period;
        await this._apiClient.dutyPeriodByPeriodIdAllowOverrideByAllowOverrideImportPut(params);
    }
}