import * as signalR from '@aspnet/signalr';
import {ComponentUrls, SignalREvents} from '@AppConstants';
import {appStore} from '@GlobalStores';
import {NotificationHandler} from '@Components';
import {ApiService} from './ApiService';

type TPayloadEvent<T> = (eventName: string, payload: T) => void;
type TEvent = () => void;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SignalREventHandler = (...args: any[]) => void;

interface ISignalRObservable {
    subscribe<T>(eventName: string, observer: TPayloadEvent<T>): void;
    unsubscribe<T>(eventName: string, observer: TPayloadEvent<T>): void;
}

type SignalRObserver = {
    eventName: string;
    observer: TEvent;
    internalObserver: SignalREventHandler;
};

class SignalRService implements ISignalRObservable {
    private _hubConnection: signalR.HubConnection | null;
    private _signalRObservers: SignalRObserver[] = [];
    private _initObservers: (() => void)[] = [];
    private _waitForConnectionPromise: Promise<void> | null;

    constructor() {
        //document.addEventListener('DOMContentLoaded', this.initialize);
        void this.initialize();
    }

    initialize = async () => {
        if (appStore.isAuthorized()) {
            await this.setUpHub();
        }
    };

    async setUpHub() {
        // eslint-disable-next-line no-restricted-globals
        const connectionUrl = location.origin +
            ComponentUrls.HubUrl +
            ComponentUrls.HubTokenQueryString +
            appStore.currentToken;

        const hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(connectionUrl)
            .build();

        hubConnection.on(SignalREvents.success, (message: string) => {
            console.log('Success: ' + message);
        });

        hubConnection.on(SignalREvents.warning, (message: string) => {
            console.log('Warning: ' + message);
        });

        hubConnection.on(SignalREvents.error, (message: string) => {
            console.log('Error: ' + message);
        });

        hubConnection.on(SignalREvents.info, (message: string) => {
            console.log('Info: ' + message);
        });

        this._signalRObservers.forEach(item => {
            hubConnection.on(item.eventName, item.internalObserver);
        });

        let reconnectMessageTimer = 0;
        let reconnect = () => {
            setTimeout(async () => {
                await this.setUpHub();
            }, 5000); // Restart connection after 5 seconds.
        };

        hubConnection.onclose(() => {
            const notificationPeriod = 1000 * 60 * 5;
            reconnectMessageTimer = window.setTimeout(() => {
                NotificationHandler.showWarning('Notifications were disconnected -> Reconnecting in 5 seconds...', 'signalReconnect');
            }, notificationPeriod);
            reconnect();
        });

        try {
            await hubConnection.start();
            console.log('SignalR connected!');

            this._initObservers.forEach(cb => cb());

            if (reconnectMessageTimer) {
                clearTimeout(reconnectMessageTimer);
                reconnectMessageTimer = 0;
            }
            this._hubConnection = hubConnection;
        } catch (e) {
            console.log(e);
            reconnect();
        }
    }

    onInitialized(cb: () => void) {
        this._initObservers.push(cb);
        return () => this._initObservers = this._initObservers.filter(sub => sub !== cb);
    }

    public subscribe<T>(eventName: string, observer: TPayloadEvent<T>) {
        const observerItem: SignalRObserver = {
            eventName: eventName,
            observer: observer as TEvent,
            internalObserver: (payload: T) => observer(eventName, ApiService.typeCheck(payload))
        };
        this._waitForConnection().then(() => {
            this._addObserver(observerItem);
        }).catch(e => {
            throw e;
        });
    }

    private _addObserver(event: SignalRObserver) {
        this._hubConnection?.on(event.eventName, event.internalObserver);
        this._signalRObservers.push(event);
    }

    public unsubscribe<T>(eventName: string, observer: TPayloadEvent<T>) {
        const observerRegistration = this._signalRObservers.find(item => item.eventName === eventName && item.observer === observer);
        if (observerRegistration) {
            const index = this._signalRObservers.indexOf(observerRegistration);
            this._signalRObservers.splice(index, 1);

            const connection = this._hubConnection;
            if (connection) {
                connection.off(eventName, observerRegistration.internalObserver);
            } else {
                throw new Error(`Registration for ${eventName} is missing`);
            }
        }
    }

    private _waitForConnection(timeout: number = 30000) {
        if (this._waitForConnectionPromise)
            return this._waitForConnectionPromise;

        this._waitForConnectionPromise = new Promise((resolve, reject) => {
            let checkIntervalId: number | undefined;

            const timeoutId = window.setTimeout(() => {
                window.clearInterval(checkIntervalId);
                this._waitForConnectionPromise = null;

                reject(new Error('Timeout waiting for signalR connection'));
            }, timeout);

            const checkConnection = () => {
                if (this._hubConnection && this._hubConnection.state === signalR.HubConnectionState.Connected) {
                    window.clearTimeout(timeoutId);
                    checkIntervalId && window.clearInterval(checkIntervalId);
                    this._waitForConnectionPromise = null;

                    resolve();
                    return true;
                }
                return false;
            };
            if (checkConnection())
                return;
            checkIntervalId = window.setInterval(checkConnection, 50);
        });
        return this._waitForConnectionPromise;
    }
}

export const signalRService = new SignalRService();