import * as React from 'react';
import './user-name.scss';

type UserNameProps = {
	name: string;
};

export class UserName extends React.PureComponent<UserNameProps> {
	render() {
		return <span className="user-name">Hello, {this.props.name}</span>;
	}
}