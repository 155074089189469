import { makeAutoObservable, runInAction } from 'mobx';
import { ApiClient, ImportTypeModel } from '@Models';

export class ImportTypesStore {
	public importTypes: ImportTypeModel[] = [];
	private _apiClient = new ApiClient();

	constructor() {
		makeAutoObservable(this);
	}

	async loadImportTypes() {
		const { data } = await this._apiClient.salaryImportTypesGet();
		runInAction(() => { this.importTypes = data; });
	}
}
