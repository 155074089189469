import * as React from 'react';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';

// import DocumentTitle from 'react-document-title';
import './report.scss';

export type ReportFilterProps<TModel extends {}> = {
    configGroup: string;
    onFilterChanged: (filter: TModel) => void;
};

type ReportProps = {
    title?: string;
    bodyClassName?: string;
    selfTitle?: boolean;
    selfContent?: boolean;
    showOnlyHeaderElement?: boolean;
    headerClassName?: string;
    headerElement?: JSX.Element | null;
    isFlexContainer?: boolean;
};

@observer
export class Report extends React.Component<ReportProps> {
    @observable private _hasError: boolean;
    @observable private _error: Error | null;
    @observable private _componentStack: string | null;

    constructor(props: ReportProps){
        super(props);
        makeObservable(this);
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        this._hasError = true;
        this._error = error;
        this._componentStack = errorInfo.componentStack;
    }

    render() {
        const { title, bodyClassName, isFlexContainer, selfTitle, selfContent, showOnlyHeaderElement, headerElement, headerClassName } = this.props;
        if (selfContent && !this._hasError) {
            return this.props.children;
        } else {
            const contentClass = `content ${isFlexContainer ? 'content-flex' : ''}`;
            const contentBodyClass = `content-body ${bodyClassName || ''}`;
            return (
                <div className={contentClass}>
                    {/* {title && <DocumentTitle title={title} />} */}
                    <div className={contentBodyClass}>
                        {!selfTitle && <h2 className="clearfix">{title}{headerElement || ''}</h2>}
                        {(showOnlyHeaderElement && selfTitle) && <h2 className={headerClassName || ''}>{headerElement || ''}</h2>}
                        {this._hasError && this.renderError()}
                        {!this._hasError && this.props.children}
                    </div>
                </div>
            );
        }
    }

    renderError() {
        const message: string = this._error ? this._error.message : 'Error';
        return (
            <div className="error-container">
                <h3>Something went wrong</h3>
                <p>Please contact support.</p>
                <div className="stack-block">
                    <h3>{message}</h3>
                    The above error occurred in:
                    {this._componentStack}
                </div>
            </div>
        );
    }
}