import { NavTab } from '@Components/Tabs/Tabs';
import * as React from 'react';

type TabContentProps = {
    activeTab?: NavTab;
};

export const TabContent: React.FunctionComponent<TabContentProps> = ({ activeTab, children }) =>  {
    return (
        <>
            {React.Children.map(children, child => child && React.cloneElement(child as JSX.Element, { activeTab }))}
        </>
    );
};