import { Environment } from '@AppConstants';
import * as React from 'react';
import './version.scss';

type VersionProps = {
	appEnvironment: Environment;
	appVersion: string;
	appName: string;
	menuTitle?: string;
	hasRenderBlocker?: boolean;
	className?: string;
};

export class Version extends React.PureComponent<VersionProps> {
	render() {
		const { appEnvironment, appVersion, hasRenderBlocker, appName, menuTitle, className } = this.props;
		const message = `ADs blocker detected, please turn it off for ${appName} to improve site performance!`;
		const cls = [];
		if (className) cls.push(className);
		cls.push(`main-nav-application  ${appEnvironment?.toLocaleLowerCase()}`);

		return (
			<div className={cls.join(' ')}> 
				{!!menuTitle && <span className="app-function">{menuTitle}</span>}
				<span className="main-nav-version">
					{appName}: <span className="app-name">{appEnvironment?.toUpperCase()}</span>
					- Version: <span className="app-version">{appVersion}</span>
					{hasRenderBlocker && 
					<span className="fa fa-exclamation-triangle exclamation" title={message} />}
				</span>
			</div>
		);
	}
}