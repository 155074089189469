import { makeObservable, observable, runInAction } from 'mobx';

import { ApiClient, PermissionType, PersonCode } from '@Models';
import { PromiseCompletion } from '@Helpers';
import { securityService } from '@Services';

export class DutyCheckStore {
    private _apiClient = new ApiClient();
    private _periodId?: number;
    public completion: PromiseCompletion = new PromiseCompletion();
    @observable.shallow public unfilledPersons: PersonCode[] = [];

    constructor() {
        makeObservable(this);
    }

    public get hasUnfilledPersons() {
        return this.unfilledPersons.length !== 0;
    }

    public get unfilledTextFilter() {
        if (!this.unfilledPersons.length) {
            return '';
        }
        const topN = Math.min(10, this.unfilledPersons.length);
        return this.unfilledPersons.slice(0, topN).map(c => c.code).join(',');
    }

    public async checkPeriodAsync(periodId?: number) {
        const hasAccessToViewSalary = securityService.hasPermission(PermissionType.ViewSalary);
        if (!hasAccessToViewSalary) return;

        let curPeriodId = periodId;
        if (curPeriodId) {
            this._periodId = curPeriodId;
        } else {
            curPeriodId = this._periodId;
        }
        if (!this._periodId) {
            return;
        }
        const { data } = await this._apiClient.dutyPeriodByPeriodIdPersonsUnfilledGet({
            periodId: +this._periodId
        }, {
            completion: this.completion
        });
        if (this._periodId !== curPeriodId) {
            return;
        }
        runInAction(() => {
            this.unfilledPersons = data;
        });
    }
}
