import React from 'react';
import './tabs.scss';
import {Tab} from '@Components';

export type NavTab = {
	id: string;
	tabName: string;
};

type TabsProps = {
	tabs: NavTab[];
	activeTab: NavTab;
	onTabSelected: (tab: NavTab) => void;
};

export const Tabs: React.FunctionComponent<TabsProps> = ({ onTabSelected, tabs, activeTab }) =>  {
	const onClick = (tab: NavTab) => {
		onTabSelected(tab);
	};

	return (
		<div className="tabs">
			{tabs.map((tab, i) =>
				<Tab
					key={i}
					active={tab.id === activeTab.id}
					onClick={() => onClick(tab)}
				>
					{tab.tabName}
				</Tab>)}
		</div>
	);
};