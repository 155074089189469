import * as React from 'react';
import { appStore } from '@GlobalStores';

export interface IWindowAdal extends Window {
    adal?: {
        redirectToLogin(): void;
        redirectToLogout(): void;
    };
}

export class AzureAuth extends React.Component<{}, {}> {

    static logOut() {
        appStore.clearUserData();
        const appWindow: IWindowAdal = window;
        appWindow.adal && appWindow.adal.redirectToLogout();
    }

    static login() {
        appStore.clearUserData();
        const appWindow: IWindowAdal = window;
        appWindow.adal && appWindow.adal.redirectToLogin();
    }

    static logOutImpersonation = () => {
        localStorage.removeItem('impersonatedUser');
        window.location.href = '/';
    };

    static exitImpersonation = () => {
        AzureAuth.logOutImpersonation();
    };

    static get isUserHasAdminRole() {
        return appStore.currentUserRoles.indexOf(UserRoles.administrator) > -1;
    }

    static get isUserHasRealAdminRole() {
        return appStore.realUserRoles.indexOf(UserRoles.administrator) > -1;
    }

    static get isReadOnly(): boolean {
        const roles = appStore.currentUserRoles;
        if (roles.indexOf(UserRoles.administrator) > -1) {
            return false;
        }
        return this.isUserHasReadRole;
    }

    static get isUserHasReadRole() {
        const roles = appStore.currentUserRoles;
        return roles && (roles.indexOf(UserRoles.ticketAgent) > -1
            || roles.indexOf(UserRoles.expenseAgent) > -1
            || roles.indexOf(UserRoles.powerSupport) > -1
            || roles.indexOf(UserRoles.support) > -1);
    }
}

export class UserRoles {
    public static readonly administrator: string = 'Vasa.Administrator';
    public static readonly ticketAgent: string = 'Vasa.TicketAgent';
    public static readonly expenseAgent: string = 'Vasa.ExpenseAgent';
    public static readonly expenseManager: string = 'Vasa.ExpenseManager';
    public static readonly support: string = 'Vasa.Support';
    public static readonly powerSupport: string = 'Vasa.PowerSupport';
}

export class AzureRole {
    public readonly name: string;
    public readonly description: string;
    public readonly roles: string[];

    constructor(name: string, description: string, rolesString: string) {
        this.name = name;
        this.description = description;
        this.roles = rolesString.split(',').map(x => x.trim());
    }
}

export class AzureRoles {
    public static ticketAgent: AzureRole = new AzureRole('Ticket agent', '', 'Vasa.TicketAgent');
    public static expenseAgent: AzureRole = new AzureRole('Expense agent', '', 'Vasa.ExpenseAgent');
    public static expenseManager: AzureRole = new AzureRole('Expense manager', '', 'Vasa.ExpenseManager');
    public static administrator: AzureRole = new AzureRole('Administrator', 'Access Vasa as a Administrator', 'Vasa.Administrator');
    public static support: AzureRole = new AzureRole('Support', '', 'Vasa.Support');
    public static powerSupport: AzureRole = new AzureRole('Power Support', '', 'Vasa.PowerSupport');
}