import * as React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import { Loading, Layout, NotificationContainer, ToolTipContainer } from '@Components';
import './App.scss';
import './custom.css';

import { requestTimelineService } from './Services/RequestTimelineService';
import { useEffect } from 'react';
import ErrorBoundary from '@Components/ErrorBoundary/ErrorBoundary';
import { navigationStore } from '@GlobalStores';

const Forbidden = React.lazy(() => import('@Pages/Forbidden/Forbidden'));
const HealthBoard = React.lazy(() => import('@Pages/HealthBoard/HealthBoard'));
const Home = React.lazy(() => import('@Pages/Home/Home'));
const Jobs = React.lazy(() => import('@Pages/Job/Jobs'));
const NotFound = React.lazy(() => import('@Pages/NotFound/NotFound'));
const RequestTimelinePage = React.lazy(() => import('@Pages/RequestTimelinePage/RequestTimelinePage'));
const RuleEditor = React.lazy(() => import('@Pages/RuleEditor/RuleEditor'));
const ImportRules = React.lazy(() => import('@Pages/ImportRules/ImportRulesDialog'));
const Configs = React.lazy(() => import('@Pages/Config/Configs'));

function App() {

    useEffect(() => {
        requestTimelineService.init();
    });

    return (
        <div className="App">
            <ErrorBoundary>
                <NotificationContainer />
                <Router ref={(r) => navigationStore.setRouter(r)}>
                    <Layout>
                        <React.Suspense fallback={<Loading isSuspense fullpage />}>
                            <Switch>
                                <Route exact path='/rule-editor' component={RuleEditor} />
                                <Route exact path='/' component={Home} />
                                <Route path='/health-board' component={HealthBoard} />
                                <Route path='/job-list' component={Jobs} />
                                <Route path='/import-rules' component={ImportRules} />
                                <Route path="/rt" component={RequestTimelinePage} />
                                <Route path="/configs" component={Configs} />
                                <Route exact path='/403' component={Forbidden} />
                                <Route exact path='/404' component={NotFound} />
                                <Redirect to="/404" />
                            </Switch>
                        </React.Suspense>
                    </Layout>
                </Router>
                <ToolTipContainer />
            </ErrorBoundary>
        </div>
    );
}

export default App;

