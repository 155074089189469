import * as React from 'react';
import { observer } from 'mobx-react';
import { action, makeObservable, observable } from 'mobx';
import * as PopperJS from '@popperjs/core';
import ReactDOM from 'react-dom';
import './tooltip.scss';

const CONTAINER_ID = 'tooltip-container';

export class ToolTipContainer extends React.Component {
    render() {
        return <div id={CONTAINER_ID} />;
    }
}

type ToolTipItemProps = {
    targetId: string;
    text?: string | JSX.Element;
    className?: string;
    placement?: PopperJS.Placement;
};

@observer
export class ToolTipItem extends React.Component<ToolTipItemProps> {
    @observable private _showPortal: boolean = false;
    @observable private _tooltipInstance: PopperJS.Instance | null = null;
    private _tooltipRef: React.RefObject<HTMLDivElement> = React.createRef();
    private _tooltipArrowRef: React.RefObject<HTMLDivElement> = React.createRef();
    private _targetElement: HTMLElement | null = null;
    private _timer: number | null = null;

    constructor(args: ToolTipItemProps){
        super(args);
        makeObservable(this);
    }

    componentDidMount() {
        this._targetElement = document.getElementById(this.props.targetId);
        if (this._targetElement) {
            this._targetElement.addEventListener('mouseenter', this._onContainerMouseOver);
            this._targetElement.addEventListener('mouseleave', this._onContainerMouseOut);
        }
    }

    componentWillUnmount() {
        if (this._targetElement) {
            this._targetElement.removeEventListener('mouseenter', this._onContainerMouseOver);
            this._targetElement.removeEventListener('mouseleave', this._onContainerMouseOut);
        }
        this._hide();
    }

    @action.bound
    private _createPopper() {
        if (!this._targetElement || !this._tooltipRef.current) return;
        const { placement } = this.props;
        this._tooltipInstance = PopperJS.createPopper(
            this._targetElement,
            this._tooltipRef.current,
            {
                placement: placement || 'right',
                modifiers: [
                    {
                        name: 'arrow',
                        options: {
                            element: this._tooltipArrowRef.current
                        }
                    }
                ]
            }
        );
    }

    @action.bound
    private _destroyPopper() {
        if (this._tooltipInstance) {
            this._tooltipInstance.destroy();
            this._tooltipInstance = null;
        }
    }

    @action.bound
    private _onContainerMouseOut() {
        this._hide();
    }

    @action.bound
    private _onContainerMouseOver() {
        this._stopTimer();
        this._showPortal = true;
        this._timer = window.setTimeout(this._createPopper, 250);
    }

    @action.bound
    private _hide() {
        this._stopTimer();
        this._destroyPopper();
        this._showPortal = false;
    }

    private _stopTimer() {
        if (this._timer) {
            window.clearTimeout(this._timer);
            this._timer = null;
        }
    }

    render() {
        const { className, children, text } = this.props;
        const cls = ['tooltip popper'];
        className && cls.push(className);
        this._tooltipInstance && cls.push('show');
        return (
            <ToolTipPortal showPortal={this._showPortal}>
                <div className={cls.join(' ')} ref={this._tooltipRef}>
                    <div className="tooltip-inner">
                        {text}
                        {children}
                    </div>
                    <div className="arrow" data-popper-arrow ref={this._tooltipArrowRef} />
                </div>
            </ToolTipPortal>
        );
    }
}

type ToolTipPortalProps = {
    showPortal: boolean;
};

class ToolTipPortal extends React.Component<ToolTipPortalProps, {}> {
    private _portalContainer: HTMLElement | null = document.getElementById(CONTAINER_ID);

    render() {
        return this._portalContainer && this.props.showPortal ? ReactDOM.createPortal(this.props.children, this._portalContainer) : null;
    }
}
