import { memo } from 'react';
import './choose-file-button.scss';

type ChooseFileButtonProps = {
	onClick: () => void;
};

function ChooseFileButton({ onClick }: ChooseFileButtonProps) {
	return (
		<div className="choose-file-btn" onClick={onClick}>
			<svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation">
				<path
					d="M13 11V3.993A.997.997 0 0 0 12 3c-.556 0-1 .445-1 .993V11H3.993A.997.997 0 0 0 3 12c0 .557.445 1 .993 1H11v7.007c0 .548.448.993 1 .993.556 0 1-.445 1-.993V13h7.007A.997.997 0 0 0 21 12c0-.556-.445-1-.993-1H13z"
					fill="currentColor"
					fillRule="evenodd"
				/>
			</svg>
			Choose File
		</div>
	);
}

const Memoized = memo(ChooseFileButton);
export { Memoized as ChooseFileButton };