import React, { ErrorInfo } from 'react';

type Boundary = {
	error: Error | null;
	errorInfo: ErrorInfo | null;
};

export default class ErrorBoundary extends React.Component<{}, Boundary> {
	constructor(props: {}) {
		super(props);
		this.state = { error: null, errorInfo: null };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		this.setState({ error,errorInfo});
	}

	render() {
		if (this.state.errorInfo) {
			return (
				<div>
				<h2>Something went wrong.</h2>
				<p>Please contact support.</p>
				<details style={{ whiteSpace: 'pre-wrap', color: 'red' }}>
					{this.state.error && this.state.error.toString()}
					<br />
					{this.state.errorInfo.componentStack}
				</details>
				</div>
			);
		}
		return this.props.children;
	}
}
  