import { NavTab } from '@Components/Tabs/Tabs';
import React from 'react';

type TabPaneProps = {
    tabId: string;
    activeTab?: NavTab;
};

export const TabPane: React.FunctionComponent<TabPaneProps> = ({ activeTab,tabId, children }) =>  {
    const isActive = activeTab && activeTab.id === tabId;
    if (!isActive) return null;

    return <>{children}</>;
};