import React from 'react';
import { FaDownload, FaSpinner } from 'react-icons/fa';
import { ApiService } from '@Services';
import { observer } from 'mobx-react';
import { action, makeObservable } from 'mobx';
import { ExportMonthOverviewFilter } from '@Models';
import { PromiseCompletion } from '@Helpers';
import { modalService } from '@Components/Modal/ModalService';
import { AxiosError } from 'axios';

type DownloadLinkProps = {
	url: string;
	className?: string;
	defaultFileName: string;
	title?: string;
	getParams?: () => ExportMonthOverviewFilter | null;
	usePostRequest?: boolean;
	responseType: 'text' | 'blob';
	icon?: JSX.Element;
};

@observer
export class DownloadLink extends React.PureComponent<DownloadLinkProps> {
	private _loader: PromiseCompletion = new PromiseCompletion();

	public static defaultProps = {
		responseType: 'text',
		usePostRequest: false
	};

	constructor(props: DownloadLinkProps) {
		super(props);
		makeObservable(this, {
			handleClick: action.bound
		});
	}

	render() {
		let cn = 'download-period';
		if (this.props.className){
			cn += ' ' + this.props.className;
		}
		if (this._loader.isPending){
			cn += ' disabled';
		}
		return (
			<a
				className={cn}
				href={this.props.url}
				onClick={this.handleClick}
				title={this.props.title}
			>
				{this._loader.isPending ? null : this.props.icon ?? <FaDownload />}
				{this._loader.isPending ? <FaSpinner className="spinner" /> : null }
			</a>
		);
	}

	public handleClick = async (e: React.MouseEvent) => {
		e.preventDefault();
		if (this._loader.isPending){
			return;
		}

		let apiUrl = (e.currentTarget as HTMLAnchorElement).getAttribute('href') ?? '';

		let params: ExportMonthOverviewFilter | null = null;
		if (this.props.getParams) {
			params = this.props.getParams();
		}

		const request = this.props.usePostRequest
			? ApiService.postData(apiUrl, params, { responseType: this.props.responseType, completion: this._loader, suppressErrorHandling: true })
			: ApiService.getData(apiUrl, params, { responseType: this.props.responseType, completion: this._loader, suppressErrorHandling: true });

		try{
			const { data, headers } = await request;
			
			const fileName = headers['content-disposition']?.match(/filename=(.+);/)?.[1] || this.props.defaultFileName;
			const contentType = headers['content-type'] || 'application/xml';

			const url = window.URL.createObjectURL(new Blob([data], { type: contentType }));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
		} catch (ex){
			const error = ex as AxiosError;
			if (error.response?.status === 404){
				await modalService.showInformation('Export file is not found, please run export first');
				return;
			}
			if (error.response?.status !== 200){
				await modalService.showInformation(`Export file downloading error: ${error.response?.status} ${error.response?.statusText || (JSON.parse(error.response?.data) as { message?: string}).message}`);
				return;
			}
		}
	};
}
  