import * as React from 'react';
import { Badge } from 'reactstrap';
import { Event } from './RequestTimelineEvents';
import { TimelineEvent, ToolTipItem } from '@Components';
import { FaBug } from 'react-icons/fa';

type RequestTimelineEventProps = Event & {
    onEventClick: () => void;
};

export class RequestTimelineEvent extends React.PureComponent<RequestTimelineEventProps, {}> {
    public render() {
        const { left, width, backgroundColor, isException, time, fullTimeMs, count, text, isSelected, onEventClick, exception } = this.props;
        const id = 'tooltip-' + fullTimeMs.replace('.', '-');

        return (
            <TimelineEvent
                classNames={isSelected ? ['event-selected'] : undefined}
                style={{ marginLeft: left }}
                onClick={onEventClick}
            >
                <div className="time" style={{ width, backgroundColor }}>
                    {isException &&
                        <>
                            <FaBug id={id} className="time-bug" />
                            {exception && <ToolTipItem text={exception} className="tooltip-wide" targetId={id} />}
                        </>
                    }
                </div>
                <b>{time}ms</b>

                {count > 1 && <Badge color="danger" pill>{count}</Badge>}
                {count > 1 && <Badge color="info" pill>{fullTimeMs}ms</Badge>}

                {text}
            </TimelineEvent>
        );
    }
}