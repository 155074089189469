import { Environment } from '@AppConstants';

declare global {
    interface Window {
        environment: Environment;
        appVersion: string;
    }
}

class DevInfoStore {
    get appVersion(): string {
        return window.appVersion; 
    }

    get appEnvironment(): Environment {
        return window.environment;
    }

    get appName(): string {
        return 'VaSa';
    }

    get isDevelopment(): boolean {
        return this.appEnvironment === Environment.Development;
    }

    get isStaging(): boolean {
        return this.appEnvironment === Environment.Staging;
    }

    get isProduction(): boolean {
        return this.appEnvironment === Environment.Production;
    }
}

export const devInfoStore = new DevInfoStore();