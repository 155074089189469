import React from 'react';
import { Input, InputProps } from '../Input/Input';

// https://stackoverflow.com/questions/48215950/exclude-property-from-type

export type DatePickerProps = Omit<InputProps, 'value' | 'onChange'> & {
	value?: Date;
	onChange?: (value?: Date) => void;
};

export class DatePicker extends React.PureComponent<DatePickerProps> {
	render() {
		const { invalid } = this.props;
		const cls = ['date-picker-wrapper'];
		const dateString = this._getStringValue();

		return (
			<Input
				className={cls.join(' ')}
				type="date" value={dateString}
				invalid={invalid}
				onChange={e => this.changeHandler(e.target.value)}
			/>
		);
	}

	private changeHandler(value: string) {
		const { onChange } = this.props;
		const dateValue = this._getDateFromString(value);
		onChange?.(dateValue);
	}

	private _getStringValue() {
		const { value } = this.props;
		let result = '';

		if (value) {
			const year =  value.getFullYear().toString();
			let month = (value.getMonth() + 1).toString();
			let date = (value.getDate()).toString();

			if (month.length === 1) month = '0' + month;
			if (date.length === 1) date = '0' + date;
			result = year + '-' + month + '-' + date;

		}
		return result;
	}

	private _getDateFromString(value: string) {
		const parts = value.split('-');
		// TODO: validation
		const year = parseInt(parts[0]);
		const month = parseInt(parts[1]);
		const day = parseInt(parts[2]);

		return new Date(year, month - 1, day) || undefined;
	}
}