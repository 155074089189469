
import React from 'react';
import { SelectFilterItem } from 'src/Models/SelectFilterItem';
import './simple-select.scss';

export type SelectProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
	invalid?: boolean;
	innerRef?: React.Ref<HTMLSelectElement>;
};

export type SimpleSelectProps = SelectProps & {
	options: (SelectFilterItem | string)[];
	hasNoEmptyOption?: boolean;
};

export class SimpleSelect extends React.PureComponent<SimpleSelectProps> {
	public render() {
		const { options, className, hasNoEmptyOption } = this.props;
		const cls = ['simple-select'];
		if (className) cls.push(className);

		return (
			<select
				className={cls.join(' ')}
				{...this.props}
			>
				{!hasNoEmptyOption && <option value="" />}
				{options.map((option: SelectFilterItem | string) => {
					const value = typeof option === 'string' ? option : option.value;
					const label = typeof option === 'string' ? option : option.label;
					return (
						<option value={value} key={value}>
							{label}
						</option>
					);
				})}
			</select>
		);
	}
}
