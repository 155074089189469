import * as React from 'react';
import { FaEye, FaEyeSlash, FaUserSecret } from 'react-icons/fa';

import { appStore } from '@GlobalStores';
import { PermissionType } from '@Models';
import { securityService } from '@Services';

import { AzureAuth } from '@Components';
import { modalService } from '@Components/Modal/ModalService';
import ImpersonationDialog from '@Components/Dialogs/ImpersonationDialog/ImpersonationDialog';
import './impersonation.scss';

type ImpersonationProps = {
	className?: string;
};

// TODO: fix - PureComponent doesnt show icons
export class Impersonation extends React.Component<ImpersonationProps> {

	constructor(props: ImpersonationProps) {
		super(props);
		this._openImpersonationTool = this._openImpersonationTool.bind(this);
	}

	public render() {
		const { className } = this.props;
		const cls = [];
		if (className) cls.push(className);
		cls.push('impersonation');

		if (appStore.impersonatedUser) {
			const roles = appStore.currentUserRoles.map(role => role.replace('CrewPage.', '')).join(', ');
			return (
				<>
					<div className={cls.join(' ')}>
						<FaUserSecret
							className="red-icon"
							title={'Roles: ' + roles}
							size={14}
							onClick={this._openImpersonationTool}
						/>
					</div>
					<div className={cls.join(' ')}>
						<FaEyeSlash
							className="red-icon"
							title="Exit impersonation"
							size={14}
							onClick={AzureAuth.exitImpersonation}
						/>
					</div>
				</>
			);
		}

		if (securityService.hasPermission(PermissionType.CanImpersonateAsAdmin) || securityService.hasPermission(PermissionType.CanImpersonate)) {
			return (
				<div className={cls.join(' ')}>
					<FaEye
						title="Impersonation"
						size={14}
						onClick={this._openImpersonationTool}
					/>
				</div>
			);
		}
		return null;
	}

	private async _openImpersonationTool() {
		const canImpersonate = securityService.hasPermission(PermissionType.CanImpersonateAsAdmin);
		const canAdminImpersonate = securityService.hasOriginalPermission(PermissionType.CanImpersonateAsAdmin);
		const canImpersonateAsAdmin = canImpersonate || canAdminImpersonate;
		await modalService.show(ImpersonationDialog, { canImpersonateAsAdmin });
	}
}