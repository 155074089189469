import React from 'react';
import {observer} from 'mobx-react';
import {runInAction} from 'mobx';
import {components, ValueType} from 'react-select';
import {FaCheck, FaClock, FaExclamationCircle, FaFile, FaFileImport} from 'react-icons/fa';

import './month-picker.scss';

import {SignalREvents} from '@AppConstants';
import {ISalaryPeriodResponseItem, SalaryPeriodResponseItem, SalaryPeriodStatus} from '@Models';

import {signalRService} from '@Services';
import {ISalaryPeriodResponseItemExtended, MonthPickerStore} from './MonthPickerStore';

import {Select} from '@Components';
import {ArrowButton} from './Comonents/ArrowButton/ArrowButton';

type MonthPickerProps = {
    nextPeriodBtn?: boolean;
    pervPeriodBtn?: boolean;
    periodStatus?: SalaryPeriodStatus;
    defaultPeriod?: SalaryPeriodResponseItem;
    getDefaultPeriod?: (options: ISalaryPeriodResponseItemExtended[]) => ISalaryPeriodResponseItemExtended | undefined;
    onChange?: (period: ISalaryPeriodResponseItem | null) => void;
};

export type PeriodProgressEvent = {
    periodId: number;
    total?: number;
    position?: number;
    text?: string;
};

type PeriodStatusEvent = {
    periodId: number;
    status: SalaryPeriodStatus;
    errorMessage?: string
};

@observer
export class MonthPicker extends React.Component<MonthPickerProps> {
    private _store: MonthPickerStore;

    constructor(props: MonthPickerProps) {
        super(props);
        this._store = new MonthPickerStore(props.onChange, props.periodStatus, props.defaultPeriod, props.getDefaultPeriod);
    }

    async componentDidMount() {
        signalRService.subscribe<PeriodProgressEvent>(SignalREvents.periodImportProgress, this._onPeriodProgress);
        signalRService.subscribe<PeriodStatusEvent>(SignalREvents.periodImportStatus, this._onPeriodStatus);
        await this._store.loadData();
    }

    componentWillUnmount() {
        signalRService.unsubscribe<PeriodProgressEvent>(SignalREvents.periodImportProgress, this._onPeriodProgress);
        signalRService.unsubscribe<PeriodStatusEvent>(SignalREvents.periodImportStatus, this._onPeriodStatus);
    }

    render() {
        const {options, selectedOption, canClickNext, canClickPrevious} = this._store;
        const {nextPeriodBtn = true, pervPeriodBtn = true} = this.props;
        return (
            <div className="month-picker">
                {/* this forces re-render :( */}
                <div style={{display: 'none'}}>{selectedOption?.position}</div>

                {pervPeriodBtn &&
                <ArrowButton
                    left
                    disabled={!canClickPrevious}
                    onClick={this._onPreviousClick}
                />}
                <Select<ISalaryPeriodResponseItemExtended>
                    options={options}
                    onChange={this._onSelectChange}
                    className="month-picker-dropdown"
                    classNamePrefix="month-picker-prefix "
                    value={selectedOption}
                    getOptionLabel={o => o.codeName ?? o.id.toString()}
                    isOptionSelected={(option, options) => !!options.find(s => option.id === s.id)}
                    components={{
                        Control: ({children, ...rest}) => {
                            return (
                                <components.Control {...rest}>
                                    {children}
                                    {!!selectedOption?.totalProgress && typeof selectedOption.position === 'number' && <div className="progress">
                                        <div className="progress-bar progress-bar-striped progress-bar-animated" style={{width: 100 * selectedOption.position / selectedOption.totalProgress + '%'}} role="progressbar"></div>
                                    </div>}
                                </components.Control>
                            );
                        }, Option: props => {
                            const item: ISalaryPeriodResponseItemExtended = props.data;
                            return (
                                <>
                                    <components.Option {...props}>
                                        {item.codeName}
                                        {item.status === 'Pending' && <span className="period-status pending" title={'No duties loaded yet'}><FaClock/></span>}
                                        {item.status === 'Imported' && <span className="period-status imported" title={'Duties loaded'}><FaFileImport/></span>}
                                        {item.status === 'Failed' && <span className="period-status failed" title={'failed due to reason: ' + item.errorMessage}><FaExclamationCircle/></span>}
                                        {item.status === 'ReadyToRelease' && <span className="period-status ready" title={'ReadyToRelease'}><FaFile/></span>}
                                        {item.status === 'Released' && <span className="period-status released" title={'Released'}><FaCheck/></span>}
                                        {!!item.totalProgress && typeof item.position === 'number' && <div className="progress">
                                            <div className="progress-bar progress-bar-striped progress-bar-animated" style={{width: 100 * item.position / item.totalProgress + '%'}} role="progressbar"></div>
                                        </div>}
                                    </components.Option>
                                </>
                            );
                        }
                    }}
                />
                {nextPeriodBtn &&
                <ArrowButton
                    right
                    disabled={!canClickNext}
                    onClick={this._onNextClick}
                />}
            </div>
        );
    }

    _onPeriodProgress = (_: string, data: PeriodProgressEvent) => {
        runInAction(() => {
            const period = this._store.options.find(x => x.id === data.periodId);
            if (period) {
                if (data.position === 0) {
                    period.status = SalaryPeriodStatus.Pending;
                    period.errorMessage = '';
                }
                period.totalProgress = data.total;
                period.position = data.position;
            }
        });
    };

    _onPeriodStatus = (_: string, data: PeriodStatusEvent) => {
        runInAction(() => {
            const period = this._store.options.find(x => x.id === data.periodId);
            if (period) {
                period.status = data.status;
                period.errorMessage = data.errorMessage;
            }
        });
    };

    private _onSelectChange = (value: ValueType<ISalaryPeriodResponseItem, false>) => {
        value && this._store.setSelectedOption(value);
    };

    private _onPreviousClick = () => {
        const {canClickPrevious, options, currentOptionIndex, setSelectedOption} = this._store;
        if (canClickPrevious) setSelectedOption(options[currentOptionIndex + 1]);
    };

    private _onNextClick = () => {
        const {canClickNext, options, currentOptionIndex, setSelectedOption} = this._store;
        if (canClickNext) setSelectedOption(options[currentOptionIndex - 1]);
    };
}
