import { makeAutoObservable, runInAction } from 'mobx';

import { CompletionType, PromiseCompletion } from '@Helpers';
import { ApiClient, RuleModel } from '@Models';

export class RulesStore {
	public rules: RuleModel[] = [];

	private _apiClient = new ApiClient();
	private _loader: PromiseCompletion = new PromiseCompletion(CompletionType.Pending);

	constructor() {
		makeAutoObservable(this);
	}

	async loadRules(dateFrom?: Date, dateTo?: Date) {
		const promise = this._apiClient.ruleGet({ date: dateFrom }); // generates interfaces with same the name
		void this._loader.subscribe(promise);
		const { data: { rules } } = await promise;

		runInAction(() => {
			if (rules) {
				this.rules = rules;
			}
		});
	}

	refreshData(dateFrom?: Date) {
		void this.loadRules(dateFrom);
	}

	public get loading() {
		return this._loader.isPending;
	}
}
