import * as React from 'react';
import { v4 as uuid } from 'uuid';
import { DateTimeService } from '@Services';
import { ToolTipItem } from '@Components';

type TimeWithDayProps = {
    dateTimeUtc: Date;
    dateTimeLt?: Date;
    dateTimeOffset?: string;
    referenceDate: Date;
    showLt?: boolean;
};

export default class TimeWithDay extends React.PureComponent<TimeWithDayProps, {}> {
    _nodeId: string = 'twd-' + uuid();

    render() {

        if (!this.props.dateTimeLt && !this.props.dateTimeOffset) {
            throw new Error('Neither dateTimeLt nor dateTimeOffset are set');
        }

        let dateTimeComputed: Date | null = null;
        let dateTimeLtComputed: Date | null = null;

        if (this.props.dateTimeLt) {
            dateTimeLtComputed = this.props.dateTimeLt;
        }
        if (this.props.dateTimeOffset) {
            dateTimeLtComputed = DateTimeService.addDiffToLt(this.props.dateTimeUtc, this.props.dateTimeOffset);
        }

        if (this.props.dateTimeLt) {
            dateTimeComputed = this.props.showLt ? dateTimeLtComputed : this.props.dateTimeUtc;
        }
        else if (this.props.dateTimeOffset) {
            dateTimeComputed = this.props.showLt ? dateTimeLtComputed : this.props.dateTimeUtc;
        }

        if (!dateTimeComputed)
            return '';

        const referenceDateTimeDiff = DateTimeService.diffDays(dateTimeComputed, this.props.referenceDate);

        const { dateTimeUtc } = this.props;
        if (!dateTimeUtc) return '';

        const diffStr = isNaN(referenceDateTimeDiff) ? '~' : referenceDateTimeDiff === 0 ? '' : (referenceDateTimeDiff > 0 ? '(+' : '(') + referenceDateTimeDiff + ') ';
        const timeString = DateTimeService.toUiTime(dateTimeComputed);
        return (
            <>
                <span id={this._nodeId}>
                    {diffStr}{timeString}
                </span>
                {this._renderToolTip(this._nodeId, this._getToolTipsText(dateTimeUtc, dateTimeLtComputed))}
            </>
        );
    }

    private _renderToolTip(targetId: string, text: JSX.Element): JSX.Element {
        return <ToolTipItem text={text} placement="top" targetId={targetId} />;
    }

    private _getToolTipsText(dateUtc: Date, dateLt: Date | null): JSX.Element {
        const dateUtcStr = DateTimeService.toUiDateTime(dateUtc);
        const dateLtStr = dateLt && DateTimeService.toUiDateTime(dateLt);
        const timeDiff = dateLt ? DateTimeService.diffMinutes(dateUtc, dateLt) : 0;
        const isInvalidTomeZone = !!(timeDiff % 30);
        return (
            <>
                UTC: {dateUtcStr}<br />
                <span className={isInvalidTomeZone ? 'invalid' : ''}>Local: {dateLtStr} {isInvalidTomeZone && 'ENZIAN Timezone might be wrong'}</span>
            </>
        );
    }
}
