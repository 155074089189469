/* eslint-disable no-extend-native */

import {DateTime} from '@AppConstants';

Date.prototype.toJSON = function () {
    //use custom implementation for better performance
    const year = this.getUTCFullYear().toString();
    let month = (this.getUTCMonth() + 1).toString();
    let day = this.getUTCDate().toString();
    let hh = this.getUTCHours().toString();
    let mm = this.getUTCMinutes().toString();
    let ss = this.getUTCSeconds().toString();

    if (month.length === 1)
        month = '0' + month;
    if (day.length === 1)
        day = '0' + day;
    if (hh.length === 1)
        hh = '0' + hh;
    if (mm.length === 1)
        mm = '0' + mm;
    if (ss.length === 1)
        ss = '0' + ss;

    //'YYYY-MM-DDTHH:MM:SS.Z'
    return `${year}-${month}-${day}T${hh}:${mm}:${ss}Z`;
};

export class DateTimeService {
    static ISO_8601: RegExp = /^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$/i;
    static ISO_8601_date: RegExp = /^\d{4}-\d\d-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?$/i;
    static regExpDateMask: RegExp = /(YYYY|MMMM|MM|M|DD|D|dd|d|HH|H|mm|ss|SSS)/g;
    static monthNames: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    static weekDayNames2Letters: string[] = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

    //#region NEW API

    static parseUiDate(value: string): Date {
        return this.parse(value, DateTime.viewDateFormat);
    }

    static parseUiDateTime(value: string): Date {
        return this.parse(value, DateTime.viewFullFormat);
    }

    static parseUiSeconds(value: string): number {
        if (!value) return NaN;
        const timeParts = value.split(':');
        if (timeParts.length > 2) return NaN;
        const hours = +timeParts[0];
        const minutes = +timeParts[1];

        let result = (hours * 60 + minutes) * 60;
        if (value.charAt(0) === '-') result = result * -1;

        return result;
    }

    static parseUiTime(date: Date, value: string): Date {
        const timeParts = value.split(':');
        const hours = +timeParts[0];
        const minutes = +timeParts[1];
        const originDate = DateTimeService.date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
        originDate.setUTCMinutes(minutes);
        originDate.setUTCHours(hours);

        return originDate;
    }

    static toUiDate(value: Date): string {
        let d = value.getUTCDate().toString();
        let m = (value.getUTCMonth() + 1).toString();

        d = d.length === 1 ? ('0' + d) : d;
        m = m.length === 1 ? ('0' + m) : m;

        return d + '.' + m + '.' + value.getUTCFullYear();
    }

    static toUiMonth(value: Date): string {
        let m = (value.getUTCMonth() + 1).toString();
        m = m.length === 1 ? ('0' + m) : m;

        return m + '.' + value.getUTCFullYear();
    }

    static toUiDateTime(value: Date): string {
        let d = value.getUTCDate().toString();
        let m = (value.getUTCMonth() + 1).toString();
        let h = value.getUTCHours().toString();
        let mm = value.getUTCMinutes().toString();

        d = d.length === 1 ? ('0' + d) : d;
        m = m.length === 1 ? ('0' + m) : m;
        h = h.length === 1 ? ('0' + h) : h;
        mm = mm.length === 1 ? ('0' + mm) : mm;

        return d + '.' + m + '.' + value.getUTCFullYear() + ' ' + h + ':' + mm;
    }

    static toUiClientShortDate(value: Date): string {
        let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let d = value.getDate().toString();
        let mIndex = value.getMonth();
        let m = monthNames[mIndex];

        return m + '\'' + d;
    }

    static toUiClientShortDateTime(value: Date): string {
        let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let d = value.getDate().toString();
        let mIndex = value.getMonth();
        let m = monthNames[mIndex];
        let h = value.getHours().toString();
        let mm = value.getMinutes().toString();

        d = d.length === 1 ? ('0' + d) : d;
        m = m.length === 1 ? ('0' + m) : m;
        h = h.length === 1 ? ('0' + h) : h;
        mm = mm.length === 1 ? ('0' + mm) : mm;

        const isToday = (new Date()).toDateString() === value.toDateString();
        return (isToday ? '' : (d + ' ' + m + ' ')) + h + ':' + mm;
    }

    static toUiTime(value: Date): string {
        let h = value.getUTCHours().toString();
        let mm = value.getUTCMinutes().toString();

        h = h.length === 1 ? ('0' + h) : h;
        mm = mm.length === 1 ? ('0' + mm) : mm;

        return h + ':' + mm;
    }

    static toUiTimestamp(value: Date): string {
        let d = value.getUTCDate().toString();
        let m = (value.getUTCMonth() + 1).toString();
        let h = value.getUTCHours().toString();
        let mm = value.getUTCMinutes().toString();
        let s = value.getUTCSeconds().toString();

        d = d.length === 1 ? ('0' + d) : d;
        m = m.length === 1 ? ('0' + m) : m;
        h = h.length === 1 ? ('0' + h) : h;
        mm = mm.length === 1 ? ('0' + mm) : mm;
        s = s.length === 1 ? ('0' + s) : s;

        return d + '.' + m + '.' + value.getUTCFullYear() + ' ' + h + ':' + mm + ':' + s;
    }

    static toUiSeconds(seconds: number): string {
        seconds = Math.trunc(seconds);
        const isNegative = seconds < 0;
        const h = Math.abs(Math.trunc(seconds / (60 * 60)));
        const m = Math.abs(Math.trunc(seconds / 60) - h * 60);
        let mm = m.toString();
        let hh = h.toString();

        mm = mm.length === 1 ? ('0' + mm) : mm;
        hh = hh.length === 1 ? ('0' + hh) : hh;

        return (isNegative ? '-' : '') + hh + ':' + mm;
    }

    static toCustomUiFormat(value: Date, format: string): string {
        return this.format(value, format);
    }

    static isSameDate(d1: Date, d2: Date): boolean {

        return d1.getUTCFullYear() === d2.getUTCFullYear() &&
            d1.getUTCMonth() === d2.getUTCMonth() &&
            d1.getUTCDate() === d2.getUTCDate();
    }

    static isSameMonth(d1: Date, d2: Date): boolean {

        return d1.getUTCFullYear() === d2.getUTCFullYear() &&
            d1.getUTCMonth() === d2.getUTCMonth();
    }

    static isAfterDate(d1: Date, d2: Date): boolean {

        if (d1.getUTCFullYear() > d2.getUTCFullYear())
            return true;
        if (d1.getUTCFullYear() < d2.getUTCFullYear())
            return false;

        if (d1.getUTCMonth() > d2.getUTCMonth())
            return true;
        if (d1.getUTCMonth() < d2.getUTCMonth())
            return false;

        return d1.getUTCDate() > d2.getUTCDate();
    }

    static isBeforeDate(d1: Date, d2: Date): boolean {

        if (d1.getUTCFullYear() < d2.getUTCFullYear())
            return true;
        if (d1.getUTCFullYear() > d2.getUTCFullYear())
            return false;

        if (d1.getUTCMonth() < d2.getUTCMonth())
            return true;
        if (d1.getUTCMonth() > d2.getUTCMonth())
            return false;

        return d1.getUTCDate() < d2.getUTCDate();
    }

    static compare(d1: Date, d2: Date): number {
        return d1.getTime() - d2.getTime();
    }

    static isValidDate(d: Date) {
        return !isNaN(d.getTime());
    }

    static diffDays(to: Date, from: Date): number {
        const date1WithoutTime = this.withTime(to, 0, 0);
        const date2WithoutTime = this.withTime(from, 0, 0);
        let diff = date1WithoutTime.getTime() - date2WithoutTime.getTime();
        return Math.ceil(diff / (24 * 60 * 60 * 1000));
    }

    static diffSeconds(to: Date, from: Date): number {

        let diff = to.getTime() - from.getTime();
        return Math.ceil(diff / 1000);
    }

    static diffMinutes(to: Date, from: Date): number {

        let diff = to.getTime() - from.getTime();
        return Math.ceil(diff / (60 * 1000));
    }

    static diffHours(to: Date, from: Date): number {

        let diff = to.getTime() - from.getTime();
        return diff / (60 * 60 * 1000);
    }

    static addDays(date: Date, days: number): Date {
        return new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
    }

    static addMonths(date: Date, months: number): Date {
        return this.date(date.getUTCFullYear(), date.getUTCMonth() + months, date.getUTCDate());
    }

    static addYears(date: Date, years: number): Date {
        return this.date(date.getUTCFullYear() + years, date.getUTCMonth(), date.getUTCDate());
    }

    static addTime(date: Date, hours: number, minutes?: number): Date {

        let offset = hours * 60 * 60 * 1000;
        if (minutes) {
            offset += minutes * 60 * 1000;
        }

        return new Date(date.getTime() + offset);
    }

    static addDiffToLt(date: Date, offset: string) {
        offset = offset.indexOf(':') ? offset.replace(':', '') : offset;
        const signChar = offset[0];
        const sign = signChar === '-' ? -1 : 1;
        const timeDiff = offset.substring(signChar === '-' || signChar === '+' ? 1 : 0);
        if (timeDiff.length !== 4) {
            throw new Error('Invalid time offset, expected format +HHMM, actual: ' + offset);
        }
        const hh = sign * +timeDiff.substring(0, 2);
        const mm = sign * +timeDiff.substring(2, 4);

        return DateTimeService.addTime(date, hh, mm);
    }

    static today(): Date {
        let today = new Date();
        return DateTimeService.date(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate());
    }

    static range(dateFrom: Date, dateTo: Date): Array<Date> {
        const days: Date[] = [];
        days.push(dateFrom);
        while (dateFrom.getTime() < dateTo.getTime()) {
            dateFrom = new Date(dateFrom.getTime() + 24 * 60 * 60 * 1000);
            days.push(dateFrom);
        }
        return days;
    }

    static fromString(value: string): Date {
        return new Date(Date.parse(value));
    }

    static withTime(date: Date, hours: number, minutes: number): Date {

        let d = DateTimeService.date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

        return DateTimeService.addTime(d, hours, minutes);
    }

    static date(year: number, month: number, day: number): Date {
        return new Date(Date.UTC(year, month, day, 0, 0, 0, 0));
    }

    static now(): Date {
        return new Date();
    }

    static startOfMonth(): Date {
        const now = this.now();
        return this.date(now.getUTCFullYear(), now.getUTCMonth(), 1);
    }

    static toFirstDayOfMonth(date: Date): Date {
        return this.date(date.getUTCFullYear(), date.getUTCMonth(), 1);
    }

    static toLastDayOfMonth(date: Date): Date {
        return this.date(date.getUTCFullYear(), date.getUTCMonth() + 1, 0);
    }

    static startOfDate(date: Date): Date {
        return this.date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    }

    static toFirstDayOfWeek(date: Date): Date {
        let dayOfWeek = date.getUTCDay() - 1;
        if (dayOfWeek < 0) {
            dayOfWeek = 6;
        }

        return this.date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() - dayOfWeek);
    }

    static toLastDayOfWeek(date: Date): Date {
        let dayOfWeek = date.getUTCDay() - 1;
        if (dayOfWeek < 0) {
            dayOfWeek = 6;
        }

        return this.date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() - dayOfWeek + 6);
    }

    static getDateIndexInRange(date: Date, dateFrom: Date, dateTo?: Date): number {
        if (date < dateFrom)
            return -1;
        if (dateTo && date > dateTo)
            return -1;
        return this.diffDays(date, dateFrom);
    }

    static calculateAge(birthday: Date, atDate: Date): number {
        let ageDifMs = atDate.getTime() - birthday.getTime();
        let ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    //#endregion

    static readonly patternCache: { [key: string]: RegExp } = {};
    static readonly patternsToRegex: { [key: string]: string } = {
        'YYYY': '(\\d{4})',
        'MMMM': '(\\S+)',
        'MM': '(\\d{2})',
        'M': '(\\d{1,2})',
        'DD': '(\\d{2})',
        'D': '(\\d{1,2})',
        'HH': '(\\d{2})',
        'H': '(\\d{1,2})',
        'mm': '(\\d{2})',
        'ss': '(\\d{2})',
        'SSS': '(\\d{3})'
    };

    static parse(value: string, format: string): Date {
        let year = 1;
        let month = 0;
        let day = 1;
        let hour = 0;
        let minute = 0;
        let second = 0;
        let milliseconds = 0;
        let pattern = DateTimeService.patternCache[format];
        let regexp: RegExp | string = '';
        const nanDate = new Date(NaN);
        const patterns: string[] = [];

        if (!pattern) {
            regexp = format.replace(this.regExpDateMask, (match, pattern) => {
                patterns.push(pattern);
                return DateTimeService.patternsToRegex[pattern];
            });

            regexp = new RegExp(regexp);
            DateTimeService.patternCache[format] = pattern;
        }

        let match = value.match(regexp);
        if (match) {
            for (let i = 1; i < match.length; i++) {
                const foundValue = patterns[i - 1];
                const valuePart = match[i];

                switch (foundValue) {
                    case 'YYYY':
                        year = this._parseSafeInteger(valuePart);
                        break;
                    case 'M':
                    case 'MM':
                        month = this._parseSafeInteger(valuePart) - 1;
                        break;
                    case 'MMMM':
                        month = this.monthNames.indexOf(valuePart);
                        if (month === -1) throw new Error('Invalid month name');
                        break;
                    case 'D':
                    case 'DD':
                        day = this._parseSafeInteger(valuePart);
                        break;
                    case 'H':
                    case 'HH':
                        hour = this._parseSafeInteger(valuePart);
                        break;
                    case 'mm':
                        minute = this._parseSafeInteger(valuePart);
                        break;
                    case 'ss':
                        second = this._parseSafeInteger(valuePart);
                        break;
                    case 'SSS':
                        milliseconds = this._parseSafeInteger(valuePart);
                        break;
                }
            }
        } else {
            return nanDate;
        }
        const newDate = new Date(Date.UTC(year, month, day, hour, minute, second, milliseconds));
        return this.format(newDate, format) === value ? newDate : nanDate;
    }

    static toQueryParam(value: Date) {
        return value.toJSON();
    }

    static getRefYear(): number {
        const date = DateTimeService.now();
        return date.getUTCMonth() <= 6 ? DateTimeService.addYears(date, -1).getUTCFullYear() : date.getUTCFullYear();
    }

    static getStartOfDay(year: number, month: number, day: number): Date {
        let result = new Date(0);
        result.setUTCFullYear(year);
        result.setUTCMonth(month);
        result.setUTCDate(day);
        return result;
    }

    private static _parseSafeInteger(value: string) {
        const parsedPart = parseInt(value, 10);
        if (isNaN(parsedPart) || parsedPart < 0 || !Number.isInteger(parsedPart)) {
            throw new Error('Failed parse ' + value + ' exception, expected format [0-9]');
        }

        return parsedPart;
    }

    static GetAllowedChangesDate(): Date {
        const dateNow = DateTimeService.today();
        const currentDay = dateNow.getUTCDate();
        const startOfMonth = DateTimeService.toFirstDayOfMonth(dateNow);
        const startOfPrevMonth = DateTimeService.addMonths(startOfMonth, -1);

        return currentDay >= 3 ? startOfMonth : startOfPrevMonth;
    }

    static format(date: Date, format?: string): string {
        if (!format) format = DateTime.viewDateFormat; //todo: replace
        let result = format;
        result = result.replace(this.regExpDateMask, (match: string) => {
            switch (match) {
                case 'DD':
                    return date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate().toString();
                case 'D':
                    return date.getUTCDate().toString();
                case 'dd':
                    let dayOfWeek = date.getUTCDay() - 1;
                    if (dayOfWeek < 0) {
                        dayOfWeek = 6;
                    }
                    return this.weekDayNames2Letters[dayOfWeek];
                case 'MMMM':
                    return this.monthNames[date.getUTCMonth()];
                case 'MM':
                    return date.getUTCMonth() + 1 < 10 ? '0' + (date.getUTCMonth() + 1).toString() : (date.getUTCMonth() + 1).toString();
                case 'M':
                    return (date.getUTCMonth() + 1).toString();
                case 'YYYY':
                    return date.getUTCFullYear().toString();
                case 'HH':
                    return date.getUTCHours() < 10 ? '0' + date.getUTCHours() : date.getUTCHours().toString();
                case 'H':
                    return date.getUTCHours().toString();
                case 'mm':
                    return date.getUTCMinutes() < 10 ? '0' + date.getUTCMinutes() : date.getUTCMinutes().toString();
                case 'ss':
                    return date.getUTCSeconds() < 10 ? '0' + date.getUTCSeconds() : date.getUTCSeconds().toString();

                default:
                    return match;
            }
        });
        return result;
    }
}